import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "../Firebase/firebaseConfig";
import { fetchAndActivate, getRemoteConfig, getString } from "firebase/remote-config";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";

/* Initialize Firebase */
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 30000;

/* Main Store */
const Store = () => {
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [usermod, setUsermod] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [authLoading, setAuthLoading] = useState(true);
  const [authState, setAuthState] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [notify, setNotify] = useState(false);
  const [plans, setPlans] = useState([]);
  const [course, setCourse] = useState({});
  const [myCampaigns, setMyCampaigns] = useState([]);
  const [featuredInfluencers, setFeaturedInfluencers] = useState({
    data: [],
    lastVisible: false,
  });
  const [popularInfluencers, setPopularInfluencers] = useState({
    data: [],
    lastVisible: false,
  });
  const [latestSponsorships, setLatestSponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [paidSponsorships, setPaidSponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [barterSponsorships, setBarterSponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [mySponsorships, setMySponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [mostAppliedSponsorships, setMostAppliedSponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [appliedSponsorships, setAppliedSponsorships] = useState({
    data: [],
    lastVisible: false,
  });
  const [recommendedSponsorships, setRecommendedSponsorships] = useState({
    data: [],
    lastVisible: false,
  });

  const [blogsData, setBlogsData] = useState({
    all: [],
    carousel: [],
    notCarousel: [],
  });

  const [allImages, setAllImages] = useState({});
  const [loaded, setLoaded] = useState([]);
  const [featuredIndex, setFeaturedIndex] = useState(0);
  const [latestIndex, setLatestIndex] = useState(0);
  const [paidIndex, setPaidIndex] = useState(0);
  const [barterIndex, setBarterIndex] = useState(0);
  const [popularIndex, setPopularIndex] = useState(0);
  const [recommendedIndex, setRecommendedIndex] = useState(0);
  const [sponsorships, setSponsorships] = useState([]);
  const [instagramData, setInstagramData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [twitterData, setTwitterData] = useState([]);
  const [tiktokData, setTiktokData] = useState([]);
  const [flytantYoutube, setFlytantYoutube] = useState({});
  const [flytantShow, setFlytantShow] = useState([]);
  const [flytantIndex, setFlytantIndex] = useState(0);
  const [notifications, setNotifications] = useState({
    all: { data: [], loading: false, loadingMore: false, hasMore: false },
    social: { data: [], loading: false, loadingMore: false, hasMore: false },
    news: { data: [], loading: false, loadingMore: false, hasMore: false },
    sponsorships: { data: [], loading: false, loadingMore: false, hasMore: false },
    dot: false,
  });
  const [blogIndex, setBlogIndex] = useState(0);
  const [myIndex, setMyIndex] = useState(0);
  const [mostIndex, setMostIndex] = useState(0);
  const [appliedIndex, setAppliedIndex] = useState(0);
  const [routes, setRoutes] = useState(false);
  const [allNews, setAllNews] = useState({ data: [], lastVisible: false });
  const [newsIndex, setNewsIndex] = useState(0);
  const [showNewsCard, setShowNewsCard] = useState(false);
  const [showSingleCard, setShowSingleCard] = useState(false);
  const [selectedSection, setSelectedScetion] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [products, setProducts] = useState([]);
  const [allReviews, setAllReviews] = useState([]);
  const [customText, setCustomText] = useState("");
  const [quantity, setQuantity] = useState(1);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeCategory, setActiveCategory] = useState("All");
  const [instagramResults, setInstagramResults] = useState({
    prev: false,
    next: false,
    data: [],
  });
  const [youtubeResults, setYoutubeResults] = useState({
    prev: false,
    next: false,
    data: [],
  });
  const [twitterResults, setTwitterResults] = useState({
    prev: false,
    next: false,
    data: [],
  });
  const [searchImages, setSearchImages] = useState({});
  const [myOrders, setMyOrders] = useState([]);
  const [screen, setScreen] = useState(window?.innerWidth >= 1024 ? true : false);
  const [allMessages, setAllMessages] = useState({
    data: [],
    unreadCount: 0,
    loading: true,
  });
  const [nftsAdmin, setNftsAdmin] = useState(false);
  const [allNfts, setAllNfts] = useState({
    data: [],
    lastVisible: false,
  });
  const [requestedNfts, setRequestedNfts] = useState({
    data: [],
    lastVisible: false,
  });
  const [approvedNfts, setApprovedNfts] = useState({
    data: [],
    lastVisible: false,
  });
  const [rejectedNfts, setRejectedNfts] = useState({
    data: [],
    lastVisible: false,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [searchFilters, setSearchFilters] = useState({});
  const [searchResults, setSearchResults] = useState({
    data: [],
    hasMore: false,
    loading: false,
    loadingMore: false,
  });
  const [searchCategories, setSearchCategories] = useState([]);
  const [brandPlans, setBrandPlans] = useState({});
  const [lists, setLists] = useState([]);
  const [brandSlider, setBrandSlider] = useState([]);
  const [extalnalLists, setExternalLIsts] = useState({ data: [], lastVisible: false });
  const [externalInfluencers, setExternalInfluencers] = useState({});
  const [externalInfluencersImages, setExternalInfluencersImages] = useState({});
  const [extalnalCampaigns, setExternalCampaigns] = useState({ data: [], lastVisible: false });
  const [externalCampaignsPosts, setExternalCampaignsPosts] = useState({});
  const [externalCampaignsImages, setExternalCampaignsImages] = useState({});
  const [brandsList, setBrandsList] = useState({ data: [], lastVisible: false });
  const [brandsListPosts, setBrandsListPosts] = useState({});
  const [brandsListImages, setBrandsListImages] = useState({});
  const [showCreate, setShowCreate] = useState(false);

  // Feed
  const [feedFrames, setFeedFrames] = useState([]);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [feedPosts, setFeedPosts] = useState({
    data: [],
    loading: false,
    loadingMore: false,
    hasMore: false,
  });
  const [userPosts, setUserPosts] = useState({
    data: [],
    loading: false,
    loadingMore: false,
    hasMore: false,
  });
  const [following, setFollowing] = useState([]);
  const [post, setPost] = useState({});

  useEffect(() => {
    const getPostBackgrounds = async () => {
      try {
        await fetchAndActivate(remoteConfig);
        const postBackgrounds = await JSON.parse(getString(remoteConfig, "postBackgrounds"));
        setFeedFrames(postBackgrounds);
      } catch (err) {}
    };
    getPostBackgrounds();
  }, []);

  useEffect(() => {
    if (user?.userId) {
      return onSnapshot(
        doc(getFirestore(), "users", user?.userId),
        (snapshot) => {
          const uData = snapshot.data();
          setNotifications((prev) => ({
            ...prev,
            dot: (uData?.latestNotificationDate || 0) > (uData?.latestNotificationCheckDate || 0),
          }));
        },
        () => {}
      );
    }
  }, [user]);

  const loadMoreSearchResults = async () => {
    try {
      if (!searchFilters.text) return;
      setSearchResults((prev) => ({ ...prev, loadingMore: true }));
      const { currentUser } = getAuth();
      const token = await currentUser.getIdToken();
      const { data } = await axios.post(
        "https://flytant.azurewebsites.net/influencerquery/get",
        { ...searchFilters, skip: searchResults?.data?.length || 0 },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSearchResults((prev) => ({
        ...prev,
        data: [...prev.data, ...data],
        hasMore: data.length === 18,
        loadingMore: false,
      }));
    } catch (err) {
      setSearchResults((prev) => ({ ...prev, loadingMore: false, hasMore: false }));
    }
  };

  useEffect(() => {
    if (!searchFilters.text) return;
    const getData = async () => {
      try {
        setSearchResults((prev) => ({ ...prev, data: [], hasMore: false, loading: true }));
        const { currentUser } = getAuth();
        const token = await currentUser.getIdToken();
        const { data } = await axios.post(
          "https://flytant.azurewebsites.net/influencerquery/get",
          searchFilters,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setSearchResults((prev) => ({
          ...prev,
          data,
          hasMore: data.length === 18,
          loading: false,
        }));
      } catch (err) {
        setSearchResults((prev) => ({ ...prev, loading: false }));
      }
    };
    getData();
  }, [searchFilters]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreen(window?.innerWidth >= 1024 ? true : false);
    });
  }, []);

  useEffect(() => {
    if (user?.userId) return;

    setMySponsorships({ data: [], lastVisible: false });
    setAppliedSponsorships({ data: [], lastVisible: false });
    setMyCampaigns([]);
    setMyIndex(0);
    setAppliedIndex(0);
    setSelectedScetion(false);
    setSelectedVideo({});
    setNotifications({
      all: { data: [], loading: false, loadingMore: false, hasMore: false },
      social: { data: [], loading: false, loadingMore: false, hasMore: false },
      news: { data: [], loading: false, loadingMore: false, hasMore: false },
      sponsorships: { data: [], loading: false, loadingMore: false, hasMore: false },
      dot: false,
    });
    setMyOrders([]);
    setPlans([]);
    setUsermod(false);
    setLists([]);
    setShowCreatePost(false);
    setFollowing([]);
    setShowCreate(false);
    setFeedPosts({ data: [], loading: false, loadingMore: false, hasMore: false });
  }, [user]);

  /* Returned Items */
  return {
    app,
    analytics,
    remoteConfig,
    user,
    setUser,
    countryCode,
    setCountryCode,
    users,
    setUsers,
    usermod,
    setUsermod,
    authLoading,
    setAuthLoading,
    authState,
    setAuthState,
    showLogin,
    setShowLogin,
    showLogout,
    setShowLogout,
    notify,
    setNotify,
    plans,
    setPlans,
    myCampaigns,
    setMyCampaigns,
    featuredInfluencers,
    setFeaturedInfluencers,
    latestSponsorships,
    setLatestSponsorships,
    paidSponsorships,
    setPaidSponsorships,
    barterSponsorships,
    setBarterSponsorships,
    popularInfluencers,
    setPopularInfluencers,
    recommendedSponsorships,
    setRecommendedSponsorships,
    sponsorships,
    setSponsorships,
    featuredIndex,
    setFeaturedIndex,
    latestIndex,
    setLatestIndex,
    paidIndex,
    setPaidIndex,
    barterIndex,
    setBarterIndex,
    popularIndex,
    setPopularIndex,
    recommendedIndex,
    setRecommendedIndex,
    instagramData,
    setInstagramData,
    youtubeData,
    setYoutubeData,
    twitterData,
    setTwitterData,
    tiktokData,
    setTiktokData,
    blogsData,
    setBlogsData,
    loaded,
    setLoaded,
    flytantYoutube,
    setFlytantYoutube,
    flytantShow,
    setFlytantShow,
    flytantIndex,
    setFlytantIndex,
    notifications,
    setNotifications,
    blogIndex,
    setBlogIndex,
    mySponsorships,
    setMySponsorships,
    mostAppliedSponsorships,
    setMostAppliedSponsorships,
    appliedSponsorships,
    setAppliedSponsorships,
    myIndex,
    setMyIndex,
    mostIndex,
    setMostIndex,
    appliedIndex,
    setAppliedIndex,
    routes,
    setRoutes,
    course,
    setCourse,
    allNews,
    setAllNews,
    newsIndex,
    setNewsIndex,
    showNewsCard,
    setShowNewsCard,
    showSingleCard,
    setShowSingleCard,
    searchKeyword,
    setSearchKeyword,
    activeCategory,
    setActiveCategory,
    instagramResults,
    setInstagramResults,
    youtubeResults,
    setYoutubeResults,
    twitterResults,
    setTwitterResults,
    searchImages,
    setSearchImages,
    selectedSection,
    setSelectedScetion,
    selectedVideo,
    setSelectedVideo,
    products,
    setProducts,
    allReviews,
    setAllReviews,
    customText,
    setCustomText,
    quantity,
    setQuantity,
    allImages,
    setAllImages,
    myOrders,
    setMyOrders,
    screen,
    allMessages,
    setAllMessages,
    nftsAdmin,
    setNftsAdmin,
    allNfts,
    setAllNfts,
    requestedNfts,
    setRequestedNfts,
    approvedNfts,
    setApprovedNfts,
    rejectedNfts,
    setRejectedNfts,
    searchFilters,
    setSearchFilters,
    showFilter,
    setShowFilter,
    searchResults,
    setSearchResults,
    loadMoreSearchResults,
    searchCategories,
    setSearchCategories,
    brandPlans,
    setBrandPlans,
    lists,
    setLists,
    brandSlider,
    setBrandSlider,
    extalnalLists,
    setExternalLIsts,
    externalInfluencers,
    setExternalInfluencers,
    externalInfluencersImages,
    setExternalInfluencersImages,
    extalnalCampaigns,
    setExternalCampaigns,
    externalCampaignsPosts,
    setExternalCampaignsPosts,
    externalCampaignsImages,
    setExternalCampaignsImages,
    brandsList,
    setBrandsList,
    brandsListPosts,
    setBrandsListPosts,
    brandsListImages,
    setBrandsListImages,
    showCreate,
    setShowCreate,
    feedFrames,
    showCreatePost,
    setShowCreatePost,
    feedPosts,
    setFeedPosts,
    userPosts,
    setUserPosts,
    following,
    setFollowing,
    post,
    setPost,
  };
};

export default Store;
