import React, { useEffect, useState } from "react";
import useStore from "../../../Store/useStore";
import { GrClose } from "react-icons/gr";
import defaultUser from "../../../Assets/defaultUser.png";
import FrameText from "./FrameText";
import Frames from "./Frames";
import FilesPreview from "./FilesPreview";
import Text from "./Text";
import Bottom from "./Bottom";
import useRequest from "../../../Hooks/useRequest";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import ModifyFiles from "./ModifyFiles";
import compress from "../../../helpers/compress";

const CreatePost = () => {
  const { user, setNotify, setShowCreatePost, setFeedPosts, setUserPosts, feedFrames } = useStore();
  const { request } = useRequest();
  const [frame, setFrame] = useState({ backgroundImageUrl: null, textColor: "" });
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);
  const [tempFiles, setTempFiles] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("1/1");

  const createPost = async () => {
    try {
      if (!text.trim()) return setNotify({ status: false, message: "Description required" });
      if (text.trim().length < 4) {
        return setNotify({
          status: false,
          message: "Please type at least 4 characters",
        });
      }
      setShowCreatePost("creating");
      const data = { description: text.trim() };
      if (frame.backgroundImageUrl) {
        data["backgroundImageUrl"] = frame.backgroundImageUrl;
        data["textColor"] = frame.textColor;
      }
      const promises = files.map(({ file }) => {
        return new Promise(async (resolve, reject) => {
          try {
            const finalFile = await compress(file);
            const fileRef = ref(
              getStorage(),
              `/feed/${user.userId}/${Date.now()}-${finalFile.name}`
            );
            const upload = await uploadBytes(fileRef, finalFile, {
              cacheControl: "public,max-age=2629800",
            });
            const url = await getDownloadURL(upload.ref);
            if (/^image\//.test(finalFile.type)) {
              return resolve({ imageUrl: url });
            }
            if (/^video\//.test(finalFile.type)) {
              return resolve({ videoUrl: url });
            }
            reject(null);
          } catch (err) {
            reject(null);
          }
        });
      });
      if (promises.length > 0 && !frame.backgroundImageUrl) {
        const blob = await Promise.all(promises);
        data["blob"] = blob;
        data["aspectRatio"] = aspectRatio;
      }
      const post = await request({ url: "feed/createpost", method: "POST", data });
      setFeedPosts((prev) => ({ ...prev, data: [post.data, ...prev.data] }));
      setUserPosts((prev) => ({ ...prev, data: [post.data, ...prev.data] }));
      setNotify({ status: true, message: "Post created successfully" });
      setShowCreatePost("");
    } catch (err) {
      setShowCreatePost("");
      setNotify({ status: false, message: "Something went wrong" });
    }
  };

  useEffect(() => {
    if (
      feedFrames.length > 0 &&
      frame.backgroundImageUrl === null &&
      files.length === 0 &&
      text.length >= 5
    ) {
      setFrame(feedFrames[Math.floor(Math.random() * feedFrames.length)]);
    }
    if (frame.backgroundImageUrl && (files.length > 0 || text.length > 140)) {
      setFrame({ backgroundImageUrl: "", textColor: "" });
    }
  }, [frame, files, text, feedFrames]);

  return (
    <>
      <div
        onClick={() => setShowCreatePost("")}
        className="fixed inset-0 bg-black/70 z-[9999999] flex items-center justify-center p-4 select-none inter"
      >
        {tempFiles.length === 0 ? (
          <div
            onClick={(e) => e.stopPropagation()}
            className="p-5 bg-white rounded-xl w-full max-w-[550px] max-h-[700px] flex flex-col gap-4"
          >
            <div className="flex items-center justify-between text-lg font-medium w-full shrink-0">
              <GrClose className="opacity-0" />
              <p>Create Post</p>
              <button onClick={() => setShowCreatePost("")}>
                <GrClose />
              </button>
            </div>
            <div className="flex items-center gap-2 shrink-0">
              <img
                loading="lazy"
                src={user?.profileImageUrl || defaultUser}
                alt=""
                className="w-12 h-12 rounded-full object-cover border"
              />
              <p className="font-medium">{user.username}</p>
            </div>

            <div className="grow flex flex-col gap-4 overflow-y-auto">
              {frame.backgroundImageUrl ? (
                <FrameText {...{ frame, text, setText }} />
              ) : (
                <Text {...{ text, setText, files }} />
              )}

              {files.length > 0 && <FilesPreview {...{ files, setFiles, aspectRatio }} />}
            </div>

            {files.length === 0 && text.length <= 140 && feedFrames?.length > 0 && (
              <Frames {...{ setFrame }} />
            )}

            <Bottom {...{ setTempFiles, createPost }} />
          </div>
        ) : (
          <ModifyFiles {...{ setFiles, setAspectRatio, tempFiles, setTempFiles }} />
        )}
      </div>
    </>
  );
};

export default CreatePost;
