const compress = (file, mw, mh) => {
  return new Promise((resolve, reject) => {
    if (!/^image\//.test(file.type)) return resolve(file);
    const maxWidth = mw || 1440;
    const maxHeight = mh || 960;
    const blobURL = URL.createObjectURL(file);
    const img = new Image();
    img.src = blobURL;
    img.onerror = reject;
    img.onload = () => {
      let width = img.width;
      let height = img.height;
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
      }
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        (blob) => {
          resolve(
            new File([blob], `${file.name.split(".")[0]}-web.jpg`, {
              type: blob.type,
            })
          );
        },
        "image/jpeg",
        0.5
      );
    };
  });
};

export default compress;
