import React, { useEffect } from "react";
import logoBlack from "../../Assets/logoBlack.png";
import cross from "../../Assets/cross.svg";
import defaultUser from "../../Assets/defaultUser.png";
import useStore from "../../Store/useStore";
import { useNavigate } from "react-router-dom";
import mikewhite from "../../Assets/navBar/mikeWhite.svg";
import contactBlack from "../../Assets/navBar/contactBlack.png";
import contactWhite from "../../Assets/navBar/contactWhite.png";
import { ReactComponent as ProfileImg } from "../../Assets/navBar/profile.svg";
import { ReactComponent as SponsorshipImg } from "../../Assets/navBar/sponsorship.svg";
import { ReactComponent as OrderImg } from "../../Assets/navBar/order.svg";
import { ReactComponent as SubscriptionImg } from "../../Assets/navBar/subscription.svg";
import { ReactComponent as LogoutImg } from "../../Assets/navBar/logout.svg";
import useLastLoggedAs from "../../Hooks/useLastLoggedAs";
import { BiPlus } from "react-icons/bi";

const styles = {
  area: "fixed top-0 left-0 w-full h-full z-30 bg-[#07070783]",
  wrapper: "fixed top-0 right-0 w-fit h-screen z-30 duration-300 bg-white",
  head: "h-14 flex items-center justify-between border-b px-5 gap-8",
  userImg: "w-[25px] h-[25px] rounded-full bg-cover bg-center bg-no-repeat",
  items: "px-2 flex flex-col gap-3 pt-5 text-sm font-medium",
  item: "flex items-center gap-4 py-2.5 rounded-md px-3 hover:bg-black hover:text-white duration-150 cursor-pointer navsideicons cp-parent [&>*>*]:hover:fill-white",
};

const SmallSide = ({ showSide, setShowSide }) => {
  const navigate = useNavigate();
  const { user, setShowLogin, setShowLogout, usermod, setShowCreate } = useStore();
  const { changeLoggedAs } = useLastLoggedAs();

  useEffect(() => {
    if (showSide) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [showSide]);

  return (
    <div className="lg:hidden z-[999]">
      {showSide && (
        <div
          onClick={() => {
            setShowSide(false);
          }}
          className={styles.area}
        />
      )}
      <div
        style={{
          transform: `translateX(${showSide ? 0 : "100%"})`,
        }}
        className={styles.wrapper}
      >
        {/* Logo+Cross */}
        <div className={styles.head}>
          <img loading="lazy" className="w-[130px]" src={logoBlack} alt="" />
          <img
            loading="lazy"
            onClick={() => {
              setShowSide(false);
            }}
            className="cursor-pointer w-6"
            src={cross}
            alt=""
          />
        </div>

        {user?.id ? (
          <div className={styles.items}>
            {/* Usermod */}
            <div className="px-3">
              <p className="px-1 text-sm text-gray-500 mb-2">I'm {!usermod ? "an" : "a"}</p>
              <div className="border h-10 flex items-center justify-between bg-[#CDCDCD] p-0.5 rounded-full text-sm mb-4">
                <button
                  onClick={() => changeLoggedAs(true)}
                  className={`h-full grow rounded-full font-medium ${
                    usermod ? "bg-black text-white" : "bg-transparent text-black"
                  }`}
                >
                  Brand
                </button>
                <button
                  onClick={() => changeLoggedAs(false)}
                  className={`h-full grow rounded-full font-medium ${
                    usermod ? "bg-transparent text-black" : "bg-black text-white"
                  }`}
                >
                  Influencer
                </button>
              </div>
            </div>
            {/* Profile */}
            <div
              onClick={() => {
                navigate(`/profile/${user?.id}`);
                setShowSide(false);
              }}
              className={styles.item}
            >
              {!user?.profileImageUrl &&
              !user?.profileImageUrl?.toLowerCase().includes("default") &&
              user?.profileImageUrl !== "" ? (
                <img
                  loading="lazy"
                  src={user?.profileImageUrl}
                  alt=""
                  className="w-5 h-5 rounded-full object-cover object-center"
                />
              ) : (
                <ProfileImg className="w-5 h-5 [&>*]:fill-black" />
              )}
              <p>Social Profile</p>
            </div>

            {/* My Campaign */}
            <div
              onClick={() => {
                navigate(usermod ? "/campaigns" : "/applied");
                setShowSide(false);
              }}
              className={styles.item}
            >
              <SponsorshipImg className="cp2 w-5 h-5 [&>*]:fill-black" />
              <p>My Campaigns</p>
            </div>

            {/* My Orders */}
            {!usermod && (
              <div
                onClick={() => {
                  navigate("/myorders");
                  setShowSide(false);
                }}
                className={styles.item}
              >
                <OrderImg className="w-5 h-5 [&>*]:fill-black" />
                <p>My Orders</p>
              </div>
            )}

            {/* Subscription */}
            <div
              onClick={() => {
                navigate(usermod ? "/brands/subscription" : "/subscription");
                setShowSide(false);
              }}
              className={styles.item}
            >
              <SubscriptionImg className="w-5 h-5 [&>*]:fill-black" />
              <p>Subscription</p>
            </div>

            {!usermod ? (
              <div className="px-3">
                <div
                  onClick={() => {
                    setShowCreate(true);
                    setShowSide(false);
                  }}
                  className="bg-black text-md text-white flex items-center justify-center gap-2.5 px-4 h-11 rounded-full mt-4 hover:scale-105 duration-150 cursor-pointer"
                >
                  <BiPlus className="w-6 h-6" />
                  <p>Create</p>
                </div>
              </div>
            ) : (
              <div className="px-3">
                <div
                  onClick={() => {
                    navigate("/createcampaign");
                    setShowSide(false);
                  }}
                  className="bg-black text-md text-white flex items-center gap-2.5 px-4 h-11 rounded-full mt-4 hover:scale-105 duration-150 cursor-pointer"
                >
                  <img loading="lazy" className="w-6" src={mikewhite} alt="" />
                  <p>Create Campaign</p>
                </div>
              </div>
            )}

            {/* Logout */}
            <div
              onClick={() => {
                setShowSide(false);
                setShowLogout(true);
              }}
              className="flex items-center w-fit px-10 gap-3 justify-center cursor-pointer h-10 border-2 border-gray-100 rounded-full font-medium hover:bg-gray-100 absolute bottom-20 left-1/2 -translate-x-1/2"
            >
              <LogoutImg className="w-5 h-5 [&>*]:fill-gray-500" />
              <p>Logout</p>
            </div>
          </div>
        ) : (
          <div className={styles.items}>
            <div
              onClick={() => {
                setShowSide(false);
                setShowLogin(true);
              }}
              className={styles.item}
            >
              <img loading="lazy" src={defaultUser} alt="" className="w-[25px]" />
              <p>Login | Signup</p>
            </div>
            <a
              onClick={() => {
                setShowSide(false);
              }}
              href="mailto:contact@flytant.com"
              target="_blank"
              rel="noreferrer"
              className={styles.item}
            >
              <img loading="lazy" src={contactBlack} alt="" className="i1" />
              <img loading="lazy" src={contactWhite} alt="" className="i2" />
              <p>Contact</p>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SmallSide;
