import { useEffect } from "react";
import useStore from "../Store/useStore";
import { getFirestore, collection, query, where, orderBy, onSnapshot } from "firebase/firestore";

const useGetAllMessages = () => {
  const { user, setAllMessages } = useStore();
  const db = getFirestore();

  useEffect(() => {
    if (!user?.userId) return;

    const unsubscribeAllMessages = onSnapshot(
      query(
        collection(db, "recentChats"),
        where("userId", "==", user?.userId),
        orderBy("creationDate", "desc")
      ),
      ({ docs }) => {
        const data = docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const unreadCount = data.reduce((prev, current) => {
          return current?.counter > 0 ? prev + 1 : prev;
        }, 0);
        setAllMessages({ data, unreadCount, loading: false });
      },
      (err) => {
        setAllMessages((prev) => ({ ...prev, loading: false }));
      }
    );

    return () => {
      unsubscribeAllMessages();
    };
  }, [user?.userId]);
};

export default useGetAllMessages;
