import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { styles } from "./CommonStyles";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useStore from "../../Store/useStore";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const SendOTP = ({ back, setShow }) => {
  const { countryCode, setNotify } = useStore();
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState();

  const send = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      if (!number || !isValidPhoneNumber(number)) {
        return setNotify({ status: false, message: "Enter a valid number" });
      }
      setLoading(true);
      const auth = getAuth();
      const appVerifier = new RecaptchaVerifier("captcha", {}, auth);
      const confirmationResult = await signInWithPhoneNumber(auth, number, appVerifier);
      window.confirmationResult = confirmationResult;
      setShow("verifyOTP");
    } catch (error) {
      setLoading(false);
      setNotify({ status: false, message: "Something went wrong" });
    }
  };

  return (
    <div className={`${styles.inputsMain} mt-10`}>
      <BiArrowBack onClick={back} className={styles.back} />
      <h1 className={styles.heading}>Phone Number</h1>
      <p className={styles.info}>Login | Sign Up</p>
      <div className="my-7">
        <p className="text-center">Enter Your mobile number</p>
        <form className={`${styles.form} items-center`} onSubmit={send}>
          <PhoneInput
            disabled={loading}
            className={`${styles.number} w-full`}
            international
            defaultCountry={countryCode || "IN"}
            countryCallingCodeEditable={false}
            value={number}
            onChange={setNumber}
          />
          <div id="captcha"></div>
          <button
            disabled={loading}
            type="submit"
            className={`text-white p-3 border-0 rounded-3xl w-full ${
              loading ? "bg-gray-400" : "bg-black"
            }`}
          >
            Send OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default SendOTP;
