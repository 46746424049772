import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ActivityCheck from "./Components/ActivityCheck/ActivityCheck";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import PrivateRoute2 from "./Components/PrivateRoute/PrivateRoute2";
import useStore from "./Store/useStore";
import UserWrapper from "./Components/Wrappers/UserWrapper";
import NftsWrapper from "./Components/Wrappers/NftsWrapper";
import BrandsWrapper from "./Components/Wrappers/BrandsWrapper";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import GlobalLoader from "./Components/GlobalLoader/GlobalLoader";
const Home = lazy(() => import("./Pages/Home"));
const PostDetails = lazy(() => import("./Pages/PostDetails"));
const SocialCard = lazy(() => import("./Pages/SocialCard"));
const User = lazy(() => import("./Pages/User"));
const Influencers = lazy(() => import("./Pages/Influencers"));
const OnBoard = lazy(() => import("./Pages/OnBoard"));
const About = lazy(() => import("./Pages/About"));
const Career = lazy(() => import("./Pages/Career"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const Ads = lazy(() => import("./Pages/Ads"));
const Terms = lazy(() => import("./Pages/Terms"));
const Contact = lazy(() => import("./Pages/Contact"));
const SponsorshipDetails = lazy(() => import("./Pages/SponsorshipDetails"));
const CreateCampaign = lazy(() => import("./Pages/CreateCampaign"));
const MyCampaigns = lazy(() => import("./Pages/MyCampaigns"));
const Subscription = lazy(() => import("./Pages/Subscription"));
const AllSubscriptions = lazy(() => import("./Components/Subscription/AllSubscriptions"));
const SubscriptionDetails = lazy(() => import("./Components/Subscription/SubscriptionDetails"));
const Success = lazy(() => import("./Components/Subscription/Success"));
const FAQs = lazy(() => import("./Components/FAQs/FAQs"));
const MyCampaignDetails = lazy(() => import("./Pages/MyCampaignDetails"));
const CampaignInfluencers = lazy(() => import("./Pages/CampaignInfluencers"));
const AllSponsorships = lazy(() => import("./Pages/AllSponsorships"));
const Recommended = lazy(() => import("./Pages/Recommended"));
const Latest = lazy(() => import("./Pages/Latest"));
const Paid = lazy(() => import("./Pages/Paid"));
const Barter = lazy(() => import("./Pages/Barter"));
const PopularInfluencers = lazy(() => import("./Pages/PopularInfluencers"));
const AllVideos = lazy(() => import("./Pages/AllVideos"));
const Notification = lazy(() => import("./Pages/Notification"));
const MostApplied = lazy(() => import("./Pages/MostApplied"));
const Applied = lazy(() => import("./Pages/Applied"));
const Courses = lazy(() => import("./Pages/Courses"));
const Index = lazy(() => import("./Components/Courses/Index"));
const Contents = lazy(() => import("./Components/Courses/Contents"));
const AllNews = lazy(() => import("./Pages/AllNews"));
const AllNewsDetails = lazy(() => import("./Pages/NewsDetails"));
const LandingApp = lazy(() => import("./Pages/LandingApp"));
const Products = lazy(() => import("./Pages/Products"));
const Main = lazy(() => import("./Components/Products/Main"));
const ProductDetails = lazy(() => import("./Components/Products/ProductDetails"));
const PaymentDetails = lazy(() => import("./Components/Products/PaymentDetails"));
const CreateInfluencersList = lazy(() => import("./Pages/CreateInfluencersList"));
const InfluencersListDetails = lazy(() => import("./Pages/InfluencersListDetails"));
const InfluencersList = lazy(() => import("./Pages/InfluencersList"));
const MyOrders = lazy(() => import("./Components/MyOrders/MyOrders"));
const AllOrders = lazy(() => import("./Components/MyOrders/AllOrders"));
const OrderDetails = lazy(() => import("./Components/MyOrders/OrderDetails"));
const InfluencerAnalytics = lazy(() => import("./Pages/InfluencerAnalytics"));
const Messages = lazy(() => import("./Pages/Messages"));
const MessageDetails = lazy(() => import("./Pages/MessageDetails"));
const MessagesIndex = lazy(() => import("./Pages/MessagesIndex"));
const AllNfts = lazy(() => import("./Pages/AllNfts"));
const Requested = lazy(() => import("./Pages/Requested"));
const Approved = lazy(() => import("./Pages/Approved"));
const Rejected = lazy(() => import("./Pages/Rejected"));
const NftsDetails = lazy(() => import("./Pages/NftsDetails"));
const CampaignLanding = lazy(() => import("./Pages/CampaignLanding"));
const SocialCardOrders = lazy(() => import("./Pages/SocialCardOrders"));
const Brands = lazy(() => import("./Pages/Brands"));
const Campaigns = lazy(() => import("./Pages/Campaigns"));
const Lists = lazy(() => import("./Pages/List"));
const ListsView = lazy(() => import("./Pages/ListsView"));
const Search = lazy(() => import("./OldPages/Search"));
const BrandSubscription = lazy(() => import("./Pages/BrandSubscription"));
const AllBrandSubscription = lazy(() =>
  import("./Components/BrandSubscription/AllBrandSubscription")
);
const Premium = lazy(() => import("./Components/BrandSubscription/Premium"));
const Enterprise = lazy(() => import("./Components/BrandSubscription/Enterprise"));
const InfluencersLists = lazy(() => import("./Pages/InfluencersLists"));
const InfluencersListsInfluencers = lazy(() => import("./Pages/InfluencersListsInfluencers"));
const InfluencerDetails = lazy(() => import("./Pages/InfluencerDetails"));
const SocialProfile = lazy(() => import("./Pages/SocialProfile"));
const EditProfile = lazy(() => import("./Pages/EditProfile"));
const OldBrand = lazy(() => import("./OldPages/Brands"));
const CampaignAnalysis = lazy(() => import("./Pages/CampaignAnalysis"));
const CampaignAnalysisDetails = lazy(() => import("./Pages/CampaignAnalysisDetails"));
const BrandsList = lazy(() => import("./Pages/BrandsList"));
const InfluencerSearch = lazy(() => import("./Pages/InfluencerSearch"));
const BrandsListDetails = lazy(() => import("./Pages/BrandsListDetails"));

// on blogs
const Blogs = lazy(() => import("./Pages/onBlogs/Blogs"));
const BlogDetails = lazy(() => import("./Pages/onBlogs/BlogDetails"));
const NewsDetails = lazy(() => import("./Pages/onBlogs/NewsDetails"));
const Articles = lazy(() => import("./Pages/onBlogs/Articles"));
const ArticleDetails = lazy(() => import("./Pages/onBlogs/ArticleDetails"));
const Media = lazy(() => import("./Pages/onBlogs/Media"));
const MediaDetails = lazy(() => import("./Pages/onBlogs/MediaDetails"));
const Events = lazy(() => import("./Pages/onBlogs/Events"));
const EventDetails = lazy(() => import("./Pages/onBlogs/EventDetails"));

// Ad pages start
const CreatorsBlogs = lazy(() => import("./Pages/AdPages/CreatorsBlogs"));
const CreatorsBlogsDetails = lazy(() => import("./Pages/AdPages/CreatorsBlogsDetails"));
const InfluencersBlogs = lazy(() => import("./Pages/AdPages/InfluencersBlogs"));
const InfluencersBlogsDetails = lazy(() => import("./Pages/AdPages/InfluencersBlogsDetails"));
const InfluencersContents = lazy(() => import("./Pages/AdPages/InfluencersContents"));
const InfluencersContentsDetails = lazy(() => import("./Pages/AdPages/InfluencersContentsDetails"));
const LatestBlogs = lazy(() => import("./Pages/AdPages/LatestBlogs"));
const LatestBlogsDetails = lazy(() => import("./Pages/AdPages/LatestBlogsDetails"));
const NewBlogs = lazy(() => import("./Pages/AdPages/NewBlogs"));
const NewBlogsDetails = lazy(() => import("./Pages/AdPages/NewBlogsDetails"));
const SocialBlogs = lazy(() => import("./Pages/AdPages/socialBlogs"));
const SocialBlogsDetails = lazy(() => import("./Pages/AdPages/SocialBlogsDetails"));
const EmailLogin = lazy(() => import("./Pages/EmailLogin"));
// Ad pages end

function App() {
  const { user, usermod } = useStore();

  return (
    <>
      <ActivityCheck />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/sponsorships"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <AllSponsorships />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <AllSponsorships />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/sponsorshipdetails/:id"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <SponsorshipDetails />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <SponsorshipDetails />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/recommended"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Recommended />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Recommended />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/latest"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Latest />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Latest />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/paid"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Paid />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Paid />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/barter"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Barter />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Barter />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/mostapplied"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <MostApplied />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <MostApplied />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/applied"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Applied />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Applied />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/popularinfluencers"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <PopularInfluencers />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <PopularInfluencers />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/allvideos"
          element={
            <PrivateRoute>
              <UserWrapper>
                <Suspense fallback={<GlobalLoader />}>
                  <AllVideos />
                </Suspense>
              </UserWrapper>
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper hideFooter={true}>
                  <Suspense fallback={<GlobalLoader />}>
                    <Notification />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper hideFooter={true}>
                  <Suspense fallback={<GlobalLoader />}>
                    <Notification />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/news"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <AllNews />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <AllNews />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/chats"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Messages />
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <UserWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <Messages />
                  </Suspense>
                </UserWrapper>
              )}
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<GlobalLoader />}>
                <MessagesIndex />
              </Suspense>
            }
          />
          <Route
            path="details/:id"
            element={
              <Suspense fallback={<GlobalLoader />}>
                <MessageDetails />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/courses"
          element={
            user?.userId ? (
              <UserWrapper>
                <Suspense fallback={<GlobalLoader />}>
                  <Courses />
                </Suspense>
              </UserWrapper>
            ) : (
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <Courses />
                <Footer />
              </Suspense>
            )
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<GlobalLoader />}>
                <Index />
              </Suspense>
            }
          />
          <Route
            path="contents"
            element={
              <Suspense fallback={<GlobalLoader />}>
                <Contents />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/influencers"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Influencers />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/nftsreview"
          element={
            <NftsWrapper>
              <Suspense fallback={<GlobalLoader />}>
                <AllNfts />
              </Suspense>
            </NftsWrapper>
          }
        />
        <Route
          path="/requested"
          element={
            <NftsWrapper>
              <Suspense fallback={<GlobalLoader />}>
                <Requested />
              </Suspense>
            </NftsWrapper>
          }
        />
        <Route
          path="/approved"
          element={
            <NftsWrapper>
              <Suspense fallback={<GlobalLoader />}>
                <Approved />
              </Suspense>
            </NftsWrapper>
          }
        />
        <Route
          path="/rejected"
          element={
            <NftsWrapper>
              <Suspense fallback={<GlobalLoader />}>
                <Rejected />
              </Suspense>
            </NftsWrapper>
          }
        />
        <Route
          path="/nftsreview/:nftId"
          element={
            <NftsWrapper>
              <Suspense fallback={<GlobalLoader />}>
                <NftsDetails />
              </Suspense>
            </NftsWrapper>
          }
        />
        <Route
          path="/campaign"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <CampaignLanding />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/app"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <LandingApp />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/onboard"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <OnBoard />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/about"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <About />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/career"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Career />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/privacy"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Privacy />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/ads"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Ads />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brands"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <BrandsWrapper>
                  <Brands />
                </BrandsWrapper>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/lists"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <BrandsWrapper>
                  <Lists />
                </BrandsWrapper>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/lists/:id"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <BrandsWrapper>
                  <ListsView />
                </BrandsWrapper>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/search"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <BrandsWrapper>
                  <Search />
                </BrandsWrapper>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <BrandsWrapper>
                  <Campaigns />
                </BrandsWrapper>
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Terms />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brand"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <OldBrand />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Contact />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/faqs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <FAQs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/socialcard"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <SocialCard />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/socialcardorders"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <SocialCardOrders />
            </Suspense>
          }
        />
        <Route
          path="/products"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <Products />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<GlobalLoader />}>
                <Main />
              </Suspense>
            }
          />
          <Route
            path="payment"
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <PaymentDetails />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path=":id"
            element={
              <Suspense fallback={<GlobalLoader />}>
                <ProductDetails />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/createinfluencerslist"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <CreateInfluencersList />
            </Suspense>
          }
        />
        <Route
          path="/influencerslist/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <InfluencersListDetails />
            </Suspense>
          }
        />
        <Route
          path="/influencerslist"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <InfluencersList />
            </Suspense>
          }
        />
        <Route
          path="/newsdetails/:id"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <AllNewsDetails />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/mycampaigns"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <MyCampaigns />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/influenceranalytics"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <InfluencerAnalytics />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/myorders"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <MyOrders />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <AllOrders />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path=":productName"
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <OrderDetails />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/mycampaigns/details/:id"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <div className="w-full h-full overflow-y-auto scrollbar pb-14">
                      <MyCampaignDetails />
                    </div>
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <>
                  <NavBar />
                  <Suspense fallback={<GlobalLoader />}>
                    <div className="w-full min-h-screen overflow-y-auto scrollbar pb-14">
                      <MyCampaignDetails />
                    </div>
                  </Suspense>
                  <Footer />
                </>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/mycampaigns/details/influencers/:id"
          element={
            <PrivateRoute>
              {usermod ? (
                <BrandsWrapper>
                  <Suspense fallback={<GlobalLoader />}>
                    <div className="w-full h-full overflow-y-auto scrollbar pb-14">
                      <CampaignInfluencers />
                    </div>
                  </Suspense>
                </BrandsWrapper>
              ) : (
                <>
                  <NavBar />
                  <Suspense fallback={<GlobalLoader />}>
                    <CampaignInfluencers />
                  </Suspense>
                  <Footer />
                </>
              )}
            </PrivateRoute>
          }
        />
        <Route
          path="/paymentsuccess"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <Success />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/createcampaign"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <CreateCampaign />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <Subscription />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <AllSubscriptions />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path=":name"
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <SubscriptionDetails />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/brands/subscription"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <BrandSubscription />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <AllBrandSubscription />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="premium"
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <Premium />
                </Suspense>
              </PrivateRoute>
            }
          />
          <Route
            path="enterprise"
            element={
              <PrivateRoute>
                <Suspense fallback={<GlobalLoader />}>
                  <Enterprise />
                </Suspense>
              </PrivateRoute>
            }
          />
        </Route>
        <Route />
        <Route
          path="/campaignanalysis"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <CampaignAnalysis />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/campaignanalysis/:id"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <CampaignAnalysisDetails />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/brandslist"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <BrandsList />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/brandslist/:id"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <BrandsListDetails />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/influencerslists"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <InfluencersLists />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/influencerslists/:id"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <InfluencersListsInfluencers />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/influencerslists/influencerdetails/:id"
          element={
            <PrivateRoute2>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <InfluencerDetails />
                <Footer />
              </Suspense>
            </PrivateRoute2>
          }
        />
        <Route
          path="/profile/:id"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <SocialProfile />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/posts/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <PostDetails />
            </Suspense>
          }
        />
        <Route
          path="/editprofile"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <EditProfile />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/influencersearch"
          element={
            <PrivateRoute>
              <Suspense fallback={<GlobalLoader />}>
                <NavBar />
                <InfluencerSearch />
                <Footer />
              </Suspense>
            </PrivateRoute>
          }
        />

        {/* onblogs start */}
        <Route
          path="/blogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Blogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/blogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <BlogDetails />
              <Footer />
            </Suspense>
          }
        />
        {/* news */}
        <Route
          path="/influencers-news/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewsDetails type="influencers-news" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brands-news/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewsDetails type="brands-news" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/advertising-news/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewsDetails type="advertising-news" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/contentcreators-news/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewsDetails type="contentcreators-news" />
              <Footer />
            </Suspense>
          }
        />

        {/* articles */}
        <Route
          path="/socialmedia-articles"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Articles type="socialmedia-articles" title="Social Media Articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/socialmedia-articles/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <ArticleDetails type="socialmedia-articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/startups-articles"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Articles type="startups-articles" title="Startup Articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/startups-articles/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <ArticleDetails type="startups-articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencers-articles"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Articles type="influencers-articles" title="Influencers Articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencers-articles/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <ArticleDetails type="influencers-articles" />
              <Footer />
            </Suspense>
          }
        />

        <Route
          path="/brands-articles"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Articles type="brands-articles" title="Brands Articles" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brands-articles/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <ArticleDetails type="brands-articles" />
              <Footer />
            </Suspense>
          }
        />
        {/* media */}
        <Route
          path="/initiatives-media"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Media type="initiatives-media" title="Media Initiatives" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/initiatives-media/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <MediaDetails type="initiatives-media" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencermarketing-media"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Media type="influencermarketing-media" title="Influencer Marketing" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencermarketing-media/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <MediaDetails type="influencermarketing-media" />
              <Footer />
            </Suspense>
          }
        />
        {/* events */}
        <Route
          path="/contentcreators-events"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Events type="contentcreators-events" title="Content Creators" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/contentcreators-events/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <EventDetails type="contentcreators-events" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brands-events"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Events type="brands-events" title="Brands Events" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/brands-events/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <EventDetails type="brands-events" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/social-events"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <Events type="social-events" title="Social Events" />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/social-events/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <EventDetails type="social-events" />
              <Footer />
            </Suspense>
          }
        />
        {/* onblogs end */}

        {/* Ad routes start */}
        <Route
          path="/creatorsblogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <CreatorsBlogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/creatorsblogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <CreatorsBlogsDetails />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencersblogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <InfluencersBlogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencersblogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <InfluencersBlogsDetails />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencerscontents"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <InfluencersContents />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/influencerscontents/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <InfluencersContentsDetails />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/latestblogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <LatestBlogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/latestblogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <LatestBlogsDetails />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/newblogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewBlogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/newblogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <NewBlogsDetails />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/socialblogs"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <SocialBlogs />
              <Footer />
            </Suspense>
          }
        />
        <Route
          path="/socialblogs/:id"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <SocialBlogsDetails />
              <Footer />
            </Suspense>
          }
        />

        <Route
          path="/emaillogin"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <EmailLogin />
              <Footer />
            </Suspense>
          }
        />
        {/* Ad routes end */}
        <Route
          path="*"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <NavBar />
              <User />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
}

export default App;
