import React, { useEffect, useState } from "react";
import logoBlack from "../../Assets/logoBlack.png";
import logo from "../../Assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import LargeTop from "./LargeTop";
import SmallSide from "./SmallSide";
import useStore from "../../Store/useStore";

const NavBar = ({ bg, to }) => {
  const { user, usermod, searchFilters } = useStore();
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const [showSide, setShowSide] = useState(false);

  const [theme, setTheme] = useState({
    bg: "bg-transparent",
    border: "border-0",
    img: logo,
    text: "text-white",
  });

  useEffect(() => {
    if (bg && !user?.userId) {
      setTheme({
        bg: "bg-transparent",
        border: "border-0",
        img: logo,
        text: "text-white",
      });
    }
    if (user?.userId && !bg) {
      setTheme({
        bg: "bg-white",
        border:
          pathname === "/search" ? (searchFilters?.text ? "border-0" : "border-b") : "border-b",
        img: logoBlack,
        text: "text-black",
      });
    }
    if (!bg && !user?.userId) {
      return setTheme({
        bg: "bg-black",
        border: "border-0",
        img: logo,
        text: "text-white",
      });
    }

    if (user?.userId && bg) {
      setTheme({
        bg: "bg-transparent",
        border: "border-0",
        img: logo,
        text: "text-white",
      });
    }
  }, [bg, user, location, searchFilters]);

  return (
    <div className={`w-full ${theme.bg}`}>
      <div className={`h-14 md:h-24 flex items-center justify-between ${theme.border} r-box`}>
        <img
          onClick={() => {
            navigate(to ? to : usermod ? "/brands" : "/");
          }}
          className="cursor-pointer w-[130px] md:w-[185px]"
          src={theme.img}
          alt=""
        />
        <div className="lg:hidden flex items-center justify-end gap-3 w-full">
          {pathname !== "/onboard" && (
            <GiHamburgerMenu
              onClick={() => {
                setShowSide(true);
              }}
              className={`text-3xl hover:border rounded-md p-1 cursor-pointer ${theme.text}`}
            />
          )}
        </div>

        <SmallSide showSide={showSide} setShowSide={setShowSide} />
        <LargeTop theme={theme} />
      </div>
    </div>
  );
};

export default NavBar;
