import React, { useState } from "react";
import { styles } from "./CommonStyles";
import { BiArrowBack } from "react-icons/bi";
import useAddUser from "../../Hooks/useAddUser";
import useStore from "../../Store/useStore";

const UserName = ({ back }) => {
  const { authLoading } = useStore();
  const { addUserToDB } = useAddUser();
  const [username, setUsername] = useState("");

  const addUser = (e) => {
    e.preventDefault();

    addUserToDB(username);
  };

  return (
    <div className={styles.inputsMain}>
      <BiArrowBack onClick={back} className={styles.back} />
      <form className={styles.usernameForm} onSubmit={addUser}>
        <p>Enter your username</p>
        <input
          disabled={authLoading}
          minLength="3"
          className={styles.username}
          placeholder="Enter username here"
          type="text"
          value={username}
          onChange={(e) => {
            if (/^[A-Za-z0-9]*$/.test(e.target.value)) {
              setUsername(e.target.value.toLowerCase().slice(0, 15));
            }
          }}
        />
        <button
          disabled={authLoading}
          type="submit"
          className={` text-white p-3 border-0 rounded-3xl w-full ${
            authLoading ? "bg-gray-400" : "bg-black"
          }`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UserName;
