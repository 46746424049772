import { useEffect } from "react";
import useStore from "../../Store/useStore";
import Footer from "../Footer/Footer";
import NavBar from "../NavBar/NavBar";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const PrivateRoute = ({ children }) => {
  const { user, authState, showLogin, setShowLogin } = useStore();

  useEffect(() => {
    if (authState) return;
    if (!user?.userId) setShowLogin(true);
  }, [authState]);

  if (authState) return <GlobalLoader strict={true} />;

  if (user?.userId) return children;

  return (
    <div className={showLogin ? "blur-sm" : ""}>
      <NavBar />
      <div className="min-h-[calc(100vh-96px)] r-box">
        {!showLogin && <p className="mt-20 text-center">You must login first</p>}
      </div>
      <Footer />
    </div>
  );
};

export default PrivateRoute;
