import React, { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { MdArrowForwardIos } from "react-icons/md";
import { GrClose } from "react-icons/gr";

const FilesPreview = ({ files, setFiles, aspectRatio }) => {
  const [_, setState] = useState(true);
  const [swiper, setSwiper] = useState({});

  const updateSwiper = useCallback((newInstance) => {
    setSwiper(newInstance);
    setState((prev) => !prev);
  }, []);

  return (
    <div className="w-full relative">
      <Swiper
        onInit={updateSwiper}
        onSlideChange={updateSwiper}
        onSlidesGridLengthChange={updateSwiper}
        className="w-full"
        style={{ aspectRatio }}
      >
        {files.map(({ file, preview }) => (
          <SwiperSlide key={file.name} className="w-full h-full flex items-center justify-center">
            <GrClose
              onClick={() => setFiles((p) => [...p].filter((f) => f.file.name !== file.name))}
              className="absolute top-4 right-4 bg-white/60 active:bg-white lg:hover:bg-white w-6 h-6 rounded-full p-1 cursor-pointer z-20"
            />

            {file.type.startsWith("image") && (
              <img
                loading="lazy"
                className="w-full h-full object-cover object-center"
                src={preview}
                alt=""
              />
            )}
            {file.type.startsWith("video") && (
              <video
                onClick={(e) => (e?.target?.paused ? e?.target?.play() : e?.target?.pause())}
                className="w-full h-full object-cover object-center tempvideo"
                src={preview}
              ></video>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {!swiper.isBeginning && files.length > 1 && (
        <div
          onClick={() => swiper?.slidePrev()}
          className="absolute top-1/2 -translate-y-1/2 left-4 z-20 rounded-full flex items-center justify-center cursor-pointer p-2 [&>*]:lg:hover:bg-white [&>*]:active:bg-white"
        >
          <MdArrowForwardIos className=" bg-white/60 text-black  w-6 h-6 rounded-full p-1 rotate-180" />
        </div>
      )}
      {!swiper.isEnd && files.length > 1 && (
        <div
          onClick={() => swiper?.slideNext()}
          className="absolute top-1/2 -translate-y-1/2 right-4 z-20 rounded-full flex items-center justify-center cursor-pointer p-2 [&>*]:lg:hover:bg-white [&>*]:active:bg-white"
        >
          <MdArrowForwardIos className=" bg-white/60 text-black  w-6 h-6 rounded-full p-1 " />
        </div>
      )}
    </div>
  );
};

export default FilesPreview;
