import React, { useState } from "react";
import useStore from "../../Store/useStore";
import defaultUser from "../../Assets/defaultUser.png";
import { TiArrowSortedDown } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ProfileImg } from "../../Assets/navBar/profile.svg";
import { ReactComponent as SponsorshipImg } from "../../Assets/navBar/sponsorship.svg";
import { ReactComponent as OrderImg } from "../../Assets/navBar/order.svg";
import { ReactComponent as SubscriptionImg } from "../../Assets/navBar/subscription.svg";
import { ReactComponent as LogoutImg } from "../../Assets/navBar/logout.svg";
import useLastLoggedAs from "../../Hooks/useLastLoggedAs";
/* Styles Start */
const styles = {
  profile: "flex items-center justify-end gap-10 text-white",
  profileImg: "w-9 h-9 md:w-10 md:h-10 rounded-full mr-2",
  contactBtn:
    "w-36 h-12 border-2 text-lg font-medium border-gray-400 flex items-center justify-center text-white rounded-md duration-150 hover:scale-105 hover:border-white hover:border-2",
  loginBtn:
    "bg-white w-48 h-12 text-lg  flex items-center justify-center rounded-md duration-150 hover:scale-105 font-medium",
};
/* Styles End */

const LargeTop = ({ theme }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, setShowLogin, setShowLogout, usermod } = useStore();
  const [show, setShow] = useState(false);
  const { changeLoggedAs } = useLastLoggedAs();

  return (
    <div className="hidden lg:block w-full">
      {user?.id ? (
        <div className={styles.profile}>
          {pathname !== "/products" && (
            <a
              href="mailto:contact@flytant.com"
              target="_blank"
              rel="noreferrer"
              className={`border-2 border-gray-500 px-7 py-2 rounded-md font-semibold ${theme.text}`}
            >
              Contact
            </a>
          )}
          <div
            onClick={() => {
              setShow(!show);
            }}
            className="flex items-center gap-1 relative"
          >
            <img
              loading="lazy"
              src={
                user?.profileImageUrl &&
                !user?.profileImageUrl?.toLowerCase().includes("default") &&
                user?.profileImageUrl !== ""
                  ? user?.profileImageUrl
                  : defaultUser
              }
              alt=""
              className="w-12 h-12 rounded-full object-cover object-center cursor-pointer shrink-0"
            />
            <TiArrowSortedDown className={`text-2xl cursor-pointer ${theme.text}`} />
            {show && (
              <>
                <div className="fixed top-0 left-0 w-screen h-screen z-[999999]" />
                <div className="absolute top-full right-0 bg-[#1f1f1f] w-[220px] p-2 pb-4 rounded-md z-[9999999]">
                  <p className="px-3 text-xs text-gray-400 my-2">I'm {!usermod ? "an" : "a"}</p>
                  <div className="py-2 mb-2 px-3 ">
                    <div className="border-2 border-gray-300 h-10 flex items-center justify-between bg-[#838383] rounded-full text-sm">
                      <button
                        onClick={() => changeLoggedAs(true)}
                        className={`h-full grow rounded-full font-medium ${
                          usermod ? "bg-black text-white" : "bg-transparent text-black"
                        }`}
                      >
                        Brand
                      </button>
                      <button
                        onClick={() => changeLoggedAs(false)}
                        className={`h-full grow rounded-full font-medium ${
                          usermod ? "bg-transparent text-black" : "bg-black text-white"
                        }`}
                      >
                        Influencer
                      </button>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      navigate(`/profile/${user?.id}`);
                    }}
                    className="hover:bg-white [&>*>*]:hover:fill-black hover:text-black py-2 my-3 px-3 text-sm font-medium rounded-md cursor-pointer flex items-center gap-4"
                  >
                    {user?.profileImageUrl &&
                    !user?.profileImageUrl?.toLowerCase().includes("default") &&
                    user?.profileImageUrl !== "" ? (
                      <img
                        loading="lazy"
                        src={user?.profileImageUrl}
                        alt=""
                        className="w-5 h-5 rounded-full object-cover object-center"
                      />
                    ) : (
                      <ProfileImg className="w-5 h-5" />
                    )}

                    <p>Social Profile</p>
                  </div>
                  <div
                    onClick={() => {
                      navigate(usermod ? "/campaigns" : "/applied");
                    }}
                    className="hover:bg-white  hover:text-black  py-2 my-3 px-3 text-sm [&>*>*]:hover:fill-black  font-medium rounded-md cursor-pointer  flex items-center gap-4 cp-parent"
                  >
                    <SponsorshipImg className="cp w-5 h-5" />
                    <p>{usermod ? "My Campaigns" : "Applied Campaigns"}</p>
                  </div>
                  {!usermod && (
                    <div
                      onClick={() => {
                        navigate(`/myorders`);
                      }}
                      className="hover:bg-white hover:text-black py-2 my-3 px-3 text-sm font-medium rounded-md cursor-pointer flex items-center gap-4 [&>*>*]:hover:fill-black"
                    >
                      <OrderImg className="w-5 h-5" />
                      <p>My Orders</p>
                    </div>
                  )}
                  <div
                    onClick={() => {
                      navigate(usermod ? "/brands/subscription" : "/subscription");
                    }}
                    className="hover:bg-white hover:text-black py-2 my-3 px-3 text-sm font-medium rounded-md cursor-pointer flex items-center gap-4 [&>*>*]:hover:fill-black"
                  >
                    <SubscriptionImg className="w-5 h-5" />
                    <p>Subscription</p>
                  </div>
                  <div
                    onClick={() => {
                      setShowLogout(true);
                    }}
                    className="hover:bg-white hover:text-black py-2 px-3 text-sm font-medium rounded-md cursor-pointer flex items-center gap-4 [&>*>*]:hover:fill-black"
                  >
                    <LogoutImg className="w-5 h-5" />
                    <p>Logout</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        pathname !== "/onboard" && (
          <div className="flex items-center justify-end gap-6">
            {pathname !== "/products" && (
              <a
                href="mailto:contact@flytant.com"
                target="_blank"
                rel="noreferrer"
                style={{ letterSpacing: ".4px" }}
                className={styles.contactBtn}
              >
                Contact
              </a>
            )}
            <button
              style={{
                letterSpacing: ".4px",
              }}
              onClick={() => {
                setShowLogin(true);
              }}
              className={styles.loginBtn}
            >
              Login | Signup
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default LargeTop;
