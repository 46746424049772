import {
  collection,
  doc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import useStore from "../Store/useStore";
import QRCode from "qrcode";
import { parsePhoneNumber } from "react-phone-number-input";

const useAddUser = () => {
  const database = getFirestore();
  const { user, setUser, setAuthLoading, setNotify, countryCode } = useStore();

  const addUserToDB = async (username) => {
    const regex = /^[0-9a-zA-Z]+$/;
    if (!username.match(regex)) {
      return setNotify({ status: false, message: "Type alphanumeric only" });
    }
    setAuthLoading(true);
    try {
      const { empty } = await getDocs(
        query(collection(database, "users"), where("username", "==", username), limit(1))
      );
      if (!empty) {
        setAuthLoading(false);
        return setNotify({ status: false, message: "Username already taken" });
      }
      const newData = { ...user.tempData, username, countryCode, qrCodeGenerated: true };
      const parsed = parsePhoneNumber(newData?.phoneNumber);
      if (parsed) {
        newData.phone.countryCode = `+${parsed.countryCallingCode}`;
        newData.phone.number = parsed.nationalNumber;
        if (!newData?.countryCode) {
          newData.countryCode = parsed.country;
        }
      }
      const file = await QRCode.toDataURL(`https://flytant.com/${newData.userId}`, { width: 350 });
      const fileRef = ref(
        getStorage(),
        `qrcode_images/${newData.userId}/${newData.userId}-at-${Date.now()}.png`
      );
      const upload = await uploadString(fileRef, file, "data_url", {
        cacheControl: "public,max-age=2629800",
      });
      const url = await getDownloadURL(upload.ref);
      newData.socialCard.qrCodeUrl = url;
      const userRef = doc(database, "users", newData.userId);
      await setDoc(userRef, newData);
      setUser({ ...newData, id: newData.userId });
      setAuthLoading(false);
    } catch (err) {
      setAuthLoading(false);
      setNotify({ status: false, message: "Something went wrong" });
    }
  };
  return { addUserToDB };
};

export default useAddUser;
