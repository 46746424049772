import React, { useEffect, useRef } from "react";

const Text = ({ text, setText, files }) => {
  const textRef = useRef();

  useEffect(() => {
    if (!textRef.current) return;
    const value = textRef.current.value;
    textRef.current.focus();
    textRef.current.value = "";
    textRef.current.value = value;
  }, []);

  useEffect(() => {
    if (!textRef.current) return;
    if (textRef.current) {
      textRef.current.style.height = "auto";
      textRef.current.style.height = textRef.current.scrollHeight + "px";
    }
  }, [text, files]);

  return (
    <textarea
      placeholder="Type your text here"
      value={text}
      onChange={(e) => setText(e.target.value.slice(0, 4000))}
      rows={files.length > 0 ? 1 : 5}
      className="w-full outline-none resize-none shrink-0"
      ref={textRef}
    ></textarea>
  );
};

export default Text;
