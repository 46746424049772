import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import nft from "../../Assets/nft/draweritems/nft.png";
import nftB from "../../Assets/nft/draweritems/nftB.png";
import request from "../../Assets/nft/draweritems/request.png";
import requestB from "../../Assets/nft/draweritems/requestB.png";
import reject from "../../Assets/nft/draweritems/reject.png";
import rejectB from "../../Assets/nft/draweritems/rejectB.png";
import approved from "../../Assets/nft/draweritems/approved.png";
import approvedB from "../../Assets/nft/draweritems/approvedB.png";
import useStore from "../../Store/useStore";

const NftDrawer = () => {
  const { allMessages } = useStore();
  const navigate = useNavigate();

  const paths = useMemo(() => {
    return [
      { title: "All NFTs", path: "/nftsreview", img1: nft, img2: nftB },
      {
        title: "Requested",
        path: "/nftsreview/requested",
        img1: request,
        img2: requestB,
      },
      {
        title: "Approved",
        path: "/nftsreview/approved",
        img1: approved,
        img2: approvedB,
      },
      {
        title: "Rejected",
        path: "/nftsreview/rejected",
        img1: reject,
        img2: rejectB,
      },
    ];
  }, []);

  const { pathname } = useLocation();

  return (
    <div className="h-full w-[50px] lg:w-[240px] overflow-y-auto scrollbar  border-r pt-5 lg:pt-8 shrink-0">
      <div className="flex flex-col items-start gap-8">
        {paths.map((path) => (
          <div
            onClick={() => {
              pathname !== path.path && navigate(path.path);
            }}
            key={path?.title}
            className="flex items-center gap-8 relative cursor-pointer h-8  pl-2 select-none"
          >
            {pathname === path.path && (
              <span className="absolute w-1 bg-black h-full top-0 left-0" />
            )}
            {path?.title?.toLowerCase() === "chats" && allMessages?.unreadCount > 0 && (
              <p className="absolute -top-2 left-7 w-5 h-5 bg-black border border-white text-white rounded-full flex items-center justify-center text-[11px]">
                {allMessages?.unreadCount}
              </p>
            )}
            <img
              loading="lazy"
              src={pathname === path.path ? path?.img2 : path.img1}
              alt=""
              className="w-7 md:w-8 aspect-square"
            />
            <span
              className={`hidden lg:block text-lg ${
                pathname === path.path ? "font-bold" : "font-medium"
              }`}
            >
              {path.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NftDrawer;
