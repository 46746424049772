import { Navigate } from "react-router-dom";
import useStore from "../../Store/useStore";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const PrivateRoute = ({ children }) => {
  const { user, authState } = useStore();

  if (authState) return <GlobalLoader strict={true} />;

  if (user?.userId) return children;

  return <Navigate to="/" />;
};

export default PrivateRoute;
