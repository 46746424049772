import React from "react";
import { AiOutlineTag } from "react-icons/ai";
import { MdOutlineImage, MdOutlineLocationOn } from "react-icons/md";

const Bottom = ({ setTempFiles, createPost }) => {
  const handleFileInput = (e) => {
    const tempFiles = [...e.target.files]
      .filter(({ type, size }) => /^image\//.test(type) && size <= 104857600)
      .map((file) => ({ file, preview: URL.createObjectURL(file) }));
    setTempFiles((prev) => {
      const allFiles = [...prev, ...tempFiles];
      const uniqueNames = Array.from(new Set(allFiles.map(({ file }) => file.name))).slice(0, 5);
      return uniqueNames.map((name) => allFiles.find(({ file }) => file.name === name));
    });
    e.target.value = null;
  };

  return (
    <div className="pt-4 border-t w-full flex items-center justify-start gap-2 shrink-0">
      <label
        className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-xl cursor-pointer"
        htmlFor="gallery"
        onChange={handleFileInput}
      >
        <input
          type="file"
          name="gallery"
          id="gallery"
          className="hidden"
          multiple
          accept="image/*"
        />
        <MdOutlineImage />
      </label>
      <button className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-xl">
        <AiOutlineTag />
      </button>
      <button className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-xl">
        <MdOutlineLocationOn />
      </button>
      <button
        onClick={createPost}
        className="h-9 w-28 bg-black/90 text-white ml-auto rounded flex items-center justify-center"
      >
        Post
      </button>
    </div>
  );
};

export default Bottom;
