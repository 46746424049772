import React, { useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "../../Assets/userHome/drawerItems/Home.png";
import HomeB from "../../Assets/userHome/drawerItems/HomeB.png";
import Campaigns from "../../Assets/brands/drawerItems/Campaign.png";
import CampaignsB from "../../Assets/brands/drawerItems/CampaignB.png";
import Chat from "../../Assets/userHome/drawerItems/Chat.png";
import ChatB from "../../Assets/userHome/drawerItems/ChatB.png";
import Notification from "../../Assets/userHome/drawerItems/Notification.png";
import NotificationB from "../../Assets/userHome/drawerItems/NotificationB.png";
import News from "../../Assets/userHome/drawerItems/News.png";
import NewsB from "../../Assets/userHome/drawerItems/NewsB.png";
import useStore from "../../Store/useStore";

const BrandsDrawer = () => {
  const { allMessages, notifications } = useStore();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(pathname);
  const navigate = useNavigate();

  const paths = useMemo(() => {
    return [
      { title: "Home", path: "/brands", img1: Home, img2: HomeB },
      { title: "Campaigns", path: "/campaigns", img1: Campaigns, img2: CampaignsB },
      { title: "Chats", path: "/chats", img1: Chat, img2: ChatB },
      { title: "Notifications", path: "/notifications", img1: Notification, img2: NotificationB },
      { title: "News", path: "/news", img1: News, img2: NewsB },
    ];
  }, []);

  useLayoutEffect(() => {
    setSelected(`/${pathname.split("/")[1]}`);
  }, [pathname]);

  return (
    <div className="h-full w-[50px] lg:w-[240px] overflow-y-auto scrollbar border-r pt-5 shrink-0">
      <div className="flex flex-col items-start gap-8">
        {paths.map((path) => (
          <div
            onClick={() => {
              selected !== path.path && navigate(path.path);
            }}
            key={path?.title}
            className="flex items-center gap-8 relative cursor-pointer h-8  pl-2 select-none"
          >
            {selected === path.path && (
              <span className="absolute w-1 bg-black h-full top-0 left-0" />
            )}
            {path?.title?.toLowerCase() === "chats" && allMessages?.unreadCount > 0 && (
              <p className="absolute -top-2 left-7 w-5 h-5 bg-black border border-white text-white rounded-full flex items-center justify-center text-[11px]">
                {allMessages?.unreadCount}
              </p>
            )}
            {path?.title?.toLowerCase() === "notifications" && notifications?.dot && (
              <span className="absolute -top-1 left-7 w-2.5 h-2.5 bg-red-500 rounded-full" />
            )}
            <img
              loading="lazy"
              src={selected === path.path ? path?.img2 : path.img1}
              alt=""
              className="w-7 md:w-8 aspect-square"
            />
            <span
              className={`hidden lg:block text-lg ${
                selected === path.path ? "font-bold" : "font-medium"
              }`}
            >
              {path.title}
            </span>
          </div>
        ))}
        <button
          onClick={() => {
            navigate("/createcampaign");
          }}
          className="bg-black text-md text-white py-3 rounded-full font-medium hidden lg:block w-[90%]"
        >
          Create Campaign
        </button>
      </div>
    </div>
  );
};

export default BrandsDrawer;
