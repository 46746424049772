import React, { useEffect } from "react";
import { useState } from "react";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import useStore from "../../Store/useStore";
import { fetchAndActivate, getString } from "firebase/remote-config";
import Spinner2 from "../Spinner/Spinner2";

const NftsLogin = () => {
  const { setNftsAdmin, remoteConfig, setNotify } = useStore();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const loginNftsAdmin = async (e) => {
    e.preventDefault();
    try {
      if (loading) return;
      setLoading(true);
      const username = e.target[0].value;
      const password = e.target[1].value;
      await fetchAndActivate(remoteConfig);
      const admins = await JSON.parse(getString(remoteConfig, "nft_admins"));
      const matched = admins.find(
        (admin) => admin.username === username && admin.password === password
      );
      if (matched) return setNftsAdmin(true);
      setNotify({ status: false, message: "Authentication error" });
      setLoading(false);
    } catch (err) {
      setNotify({ status: false, message: "Something went wrong" });
      setLoading(false);
    }
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div className=" w-[95%] max-w-[400px] p-5 lg:p-10 rounded-xl shadow-lg border relative overflow-hidden">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-[#fdfcfc7c]">
            <Spinner2 />
          </div>
        )}
        <p className="text-lg lg:text-xl font-semibold text-center">Login</p>
        <form onSubmit={loginNftsAdmin}>
          <input
            required
            type="text"
            className="p-2 border outline-none w-full mt-4 rounded-lg"
            placeholder="Username"
          />
          <div className="flex items-center gap-2 border rounded-lg mt-4 overflow-hidden pr-2">
            <input
              required
              type={show ? "text" : "password"}
              className="p-2 outline-none w-full"
              placeholder="Password"
            />
            {show ? (
              <AiFillEye
                className="text-xl select-none"
                onClick={() => setShow(false)}
              />
            ) : (
              <AiOutlineEye
                className="text-xl select-none"
                onClick={() => setShow(true)}
              />
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-black text-white mt-5 p-2 rounded-lg font-medium"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NftsLogin;
