import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import useStore from "../Store/useStore";

const useLastLoggedAs = () => {
  const { user, setUsermod } = useStore();
  const db = getFirestore();
  const navigate = useNavigate();

  const changeLoggedAs = (status) => {
    try {
      updateDoc(doc(db, "users", user.userId), {
        lastLoggedAs: status ? "brand" : "influencer",
      });
      setUsermod(status);
      navigate(status ? "/brands" : "/");
    } catch (err) {}
  };

  return { changeLoggedAs };
};
export default useLastLoggedAs;
