import React, { useEffect, useState } from "react";
import cross from "../../Assets/cross.svg";
import useStore from "../../Store/useStore";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import like from "../../Assets/news/Like.png";
import liked from "../../Assets/news/Liked.png";
import comment from "../../Assets/news/Comment.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper";
import "swiper/css";
import {
  getFirestore,
  doc,
  increment,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import Comment from "./Comment";

const NewsCard = () => {
  const { showNewsCard, setShowNewsCard, allNews, setAllNews, user, setUser } = useStore();
  const [activeIndex, setActiveIndex] = useState(showNewsCard - 1);
  const [showComment, setShowComment] = useState(false);
  const [swiper, setSwiper] = useState({});
  const db = getFirestore();

  const inCrease = async (newsId) => {
    // Update to Local
    const matched = allNews.data.find((item) => item.id === newsId);
    const likeCount = parseInt(matched?.likeCount || 0);
    const updatedNews = { ...matched, likeCount: likeCount + 1 };
    const mergedNews = allNews.data.map((item) => (item.id === newsId ? updatedNews : item));
    setAllNews((prev) => ({ ...prev, data: mergedNews }));
    setUser((prev) => ({
      ...prev,
      likedNews: prev?.likedNews ? [...prev?.likedNews, newsId] : [newsId],
    }));
    // Update to DB
    const newsRef = doc(db, "news", newsId);
    const userRef = doc(db, "users", user?.userId);
    updateDoc(newsRef, {
      likeCount: increment(1),
    });
    updateDoc(userRef, {
      likedNews: arrayUnion(newsId),
    });
  };
  const deCrease = async (newsId) => {
    // Update to Local
    const { likeCount, ...rest } = allNews.data.find((item) => item.id === newsId);
    const updatedNews = { ...rest, likeCount: likeCount - 1 };
    const mergedNews = allNews.data.map((item) => (item.id === newsId ? updatedNews : item));
    const updatedUser = user?.likedNews?.filter((item) => item !== newsId);
    setAllNews((prev) => ({ ...prev, data: mergedNews }));
    setUser((prev) => ({ ...prev, likedNews: updatedUser }));
    // Update to DB
    const newsRef = doc(db, "news", newsId);
    const userRef = doc(db, "users", user?.userId);
    updateDoc(newsRef, {
      likeCount: increment(-1),
    });
    updateDoc(userRef, {
      likedNews: arrayRemove(newsId),
    });
  };

  useEffect(() => {
    const matched = allNews?.data[activeIndex];
    if (!matched?.newsId) return;
    const newsRef = doc(db, "news", matched.newsId);
    updateDoc(newsRef, { read: increment(1) });
  }, [activeIndex]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "auto");
  }, []);

  return (
    <>
      <div className="fixed inset-0 top-0 left-0 bg-[#030303f6] z-30 flex items-center justify-center">
        <img
          loading="lazy"
          onClick={() => {
            setShowNewsCard(false);
          }}
          className="bg-[#ffffff86] absolute top-3 right-3 lg:top-8 lg:right-8 w-5 md:w-7 lg:w-9 rounded-full cursor-pointer hover:bg-white"
          src={cross}
          alt=""
        />
        <div
          className={`hidden md:block absolute left-5 top-1/2 -translate-y-1/2 text-[#ffffff86] hover:text-white md:text-6xl lg:text-8xl  cursor-pointer select-none ${
            swiper?.isBeginning ? "invisible" : "visible"
          }`}
          onClick={() => swiper?.slidePrev()}
        >
          <MdNavigateBefore />
        </div>
        <div
          className={`hidden md:block absolute right-5 top-1/2 -translate-y-1/2  text-[#ffffff86] hover:text-white md:text-6xl lg:text-8xl cursor-pointer select-none ${
            swiper?.isEnd ? "invisible" : "visible"
          }`}
          onClick={() => swiper?.slideNext()}
        >
          <MdNavigateNext />
        </div>
        <Swiper
          modules={[Navigation, Keyboard]}
          keyboard={{ enabled: true }}
          onSlideChange={(val) => {
            setShowComment(false);
            setActiveIndex(val?.realIndex);
          }}
          onInit={setSwiper}
          initialSlide={activeIndex}
          onSwiper={setSwiper}
          spaceBetween={10}
          slidesPerView={1.1}
          breakpoints={{
            768: {
              slidesPerView: 1,
            },
          }}
          className="w-[95%] max-w-[450px] h-[80vh]"
        >
          {allNews?.data?.map((item, index) => (
            <SwiperSlide
              key={index}
              className="flex flex-col justify-between bg-white rounded-md overflow-hidden"
            >
              <div className="w-full overflow-y-auto scrollbar">
                <div
                  style={{
                    backgroundImage: `url("${item?.blob[0]?.path}")`,
                  }}
                  className="bg-cover bg-center bg-no-repeat aspect-[7/4]"
                />

                <div className="px-5">
                  <p className="font-semibold mt-2 text-lg">{item?.title}</p>
                  <p style={{ lineHeight: "200%" }} className="mt-2 text-sm">
                    {item?.description}
                  </p>
                </div>
              </div>

              <div className="p-2">
                <div className="flex items-center justify-center gap-7 font-medium">
                  <div className="flex items-center justify-center gap-1 ">
                    <img
                      loading="lazy"
                      onClick={() =>
                        user?.likedNews?.includes(item?.id)
                          ? deCrease(item?.id)
                          : inCrease(item?.id)
                      }
                      src={user?.likedNews?.includes(item?.id) ? liked : like}
                      className="cursor-pointer w-8"
                      alt=""
                    />

                    <p>{item?.likeCount}</p>
                  </div>
                  <div className="flex items-center justify-center gap-1">
                    <img
                      loading="lazy"
                      onClick={() => {
                        setShowComment(item?.id);
                      }}
                      className="cursor-pointer w-8"
                      src={comment}
                      alt=""
                    />
                    <p>{item?.commentCount}</p>
                  </div>
                </div>
              </div>
              {showComment === item?.id && (
                <Comment setShowComment={setShowComment} newsId={item?.id} setData={false} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      ;
    </>
  );
};

export default NewsCard;
