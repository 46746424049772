import axios from "axios";
import { getAuth } from "firebase/auth";

const client = axios.create({ baseURL: "https://flytant.in/api" });
// const client = axios.create({ baseURL: "http://localhost:5000/api" });

const useRequest = () => {
  const request = async ({ ...options }) => {
    const { currentUser } = getAuth();
    if (currentUser) {
      const token = await currentUser.getIdToken();
      client.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    const onSuccess = (response) => response;
    const onError = (error) => {
      throw new Error(error?.response?.data?.message || "Something went wrong");
    };
    return client(options).then(onSuccess).catch(onError);
  };

  return { request };
};
export default useRequest;
