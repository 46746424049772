import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import UserDrawer from "../Drawer/UserDrawer";

const UserWrapper = ({ children, hideFooter }) => {
  return (
    <div className="fixed inset-0 overflow-y-auto">
      <NavBar />
      <div
        className={`h-[calc(100%-56px)] md:h-[calc(100%-96px)] flex items-start r-box ${
          !hideFooter ? "mb-20" : ""
        }`}
      >
        <UserDrawer />
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default UserWrapper;
