import { GrClose } from "react-icons/gr";
import useStore from "../../Store/useStore";
import { useNavigate } from "react-router-dom";
import postIcon from "../../Assets/create/postIcon.png";
import campaignIcon from "../../Assets/create/campaignIcon.png";

const Create = () => {
  const { setShowCreate, setShowCreatePost } = useStore();
  const navigate = useNavigate();

  return (
    <div
      onClick={() => setShowCreate(false)}
      className="fixed inset-0 bg-black/70 z-[9999] flex items-center justify-center p-4 inter select-none"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white w-full max-w-[650px] rounded-xl"
      >
        <div className="border-b flex items-center justify-between py-4 px-6">
          <p className="text-xl font-semibold text-black/90 pl-2">Create</p>
          <GrClose onClick={() => setShowCreate(false)} className="w-9 h-9 p-2 cursor-pointer" />
        </div>
        <div className="py-14 flex flex-col sm:flex-row items-center justify-center sm:justify-evenly px-4 gap-10 sm:gap-0 lg:py-20">
          <div className="flex flex-col items-center justify-center">
            <button
              onClick={() => {
                setShowCreate(false);
                setShowCreatePost((prev) => prev || "create");
              }}
              className="w-32 h-32 shadow-xl feedshadowall rounded-2xl flex items-center justify-center"
            >
              <img loading="lazy" src={postIcon} alt="" />
            </button>
            <div className="text-center mt-4">
              <p className="text-xl font-semibold text-black/90">Post</p>
              <p className="text-sm text-neutral-500 mt-1">Create Content</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <button
              onClick={() => {
                setShowCreate(false);
                navigate("/createcampaign");
              }}
              className="w-32 h-32 shadow-xl feedshadowall rounded-2xl flex items-center justify-center"
            >
              <img loading="lazy" src={campaignIcon} alt="" />
            </button>
            <div className="text-center mt-4">
              <p className="text-xl font-semibold text-black/90">Campaign</p>
              <p className="text-sm text-neutral-500 mt-1">For Brands | Agencies</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create;
