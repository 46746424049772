import React, { useEffect, useRef } from "react";

const FrameText = ({ frame, text, setText }) => {
  const frameInputRef = useRef();

  useEffect(() => {
    if (!frameInputRef.current) return;
    const value = frameInputRef.current.value;
    frameInputRef.current.focus();
    frameInputRef.current.value = "";
    frameInputRef.current.value = value;
  }, []);

  useEffect(() => {
    if (!frameInputRef.current) return;
    frameInputRef.current.style.height = text ? `${frameInputRef.current.scrollHeight}px` : "auto";
  }, [text]);

  return (
    <div className="w-full relative min-h-[290px] sm:min-h-[320px] lg:min-h-[350px] xl:min-h-[380px] flex items-center justify-center p-4 sm:p-6 lg:p-8 xl:p-10 shrink-0">
      <img
        loading="lazy"
        src={frame.backgroundImageUrl}
        alt=""
        className="absolute top-0 left-0 w-full h-full object-cover object-center"
      />
      <textarea
        placeholder="Type your text here"
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows="1"
        style={{ color: frame.textColor }}
        className={`relative outline-none w-full text-center px-4 bg-transparent font-semibold placeholder:text-neutral-500/70 resize-none ${
          text.length > 100
            ? "text-lg lg:text-xl"
            : text.length > 60
            ? "text-xl lg:text-2xl"
            : "text-2xl lg:text-3xl"
        }`}
        ref={frameInputRef}
      ></textarea>
    </div>
  );
};

export default FrameText;
