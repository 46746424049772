import React, { useRef, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { IoBanOutline } from "react-icons/io5";
import useStore from "../../../Store/useStore";

const Frames = ({ setFrame }) => {
  const { feedFrames } = useStore();
  const framesRef = useRef();
  const [arrows, setArrows] = useState({ left: false, right: true });

  const onScroll = () => {
    if (!framesRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = framesRef.current;
    setArrows({ left: scrollLeft !== 0, right: scrollLeft + clientWidth !== scrollWidth });
  };

  return (
    <div className="flex items-center gap-2 w-full shrink-0">
      {arrows.left && (
        <button
          onClick={() => {
            if (!framesRef?.current?.scroll) return;
            framesRef.current.scroll({
              left: framesRef.current.scrollLeft - 44,
              behavior: "smooth",
            });
          }}
          className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-lg shrink-0 rotate-180"
        >
          <MdArrowForwardIos />
        </button>
      )}
      <button
        onClick={() => setFrame({ backgroundImageUrl: "", textColor: "" })}
        className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-lg shrink-0 rotate-180"
      >
        <IoBanOutline />
      </button>
      <div className="grow h-9 overflow-hidden">
        <div
          onScroll={onScroll}
          ref={framesRef}
          className="w-full flex items-center gap-2 overflow-x-auto"
        >
          {feedFrames.map((frame, index) => (
            <img
              loading="lazy"
              key={index}
              src={frame.backgroundImageUrl}
              alt=""
              className="w-9 h-9 rounded cursor-pointer object-cover object-center"
              onClick={() => setFrame(frame)}
            />
          ))}
        </div>
      </div>
      {arrows.right && (
        <button
          onClick={() => {
            if (!framesRef?.current?.scroll) return;
            framesRef.current.scroll({
              left: framesRef.current.scrollLeft + 44,
              behavior: "smooth",
            });
          }}
          className="w-9 h-9 bg-[#EEEEEE] rounded flex items-center justify-center text-lg shrink-0"
        >
          <MdArrowForwardIos />
        </button>
      )}
    </div>
  );
};

export default Frames;
