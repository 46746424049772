import React from "react";
import useAuthCheck from "../../Hooks/useAuthCheck";
import useStore from "../../Store/useStore";
import Login from "../Login/Login";
import Logout from "../Logout/Logout";
import Toast from "../Toast/Toast";
import useScrollToTop from "../../Hooks/useScrollToTop";
import NewsCard from "../NewsCard/NewsCard";
import SingleCard from "../NewsCard/SingleCard";
import useGetAllMessages from "../../Hooks/useGetAllMessages";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import CreatePost from "../Feed/CreatePost/CreatePost";
import useFollowing from "../../Hooks/useFollowing";
import Create from "../Create/Create";
import { BarLoader } from "react-spinners";

const ActivityCheck = () => {
  const {
    authLoading,
    authState,
    showLogin,
    showLogout,
    notify,
    showNewsCard,
    showSingleCard,
    showCreatePost,
    showCreate,
  } = useStore();
  useAuthCheck();
  useGetAllMessages();
  useScrollToTop();
  useFollowing();

  return (
    <>
      {showLogin && <Login />}
      {showLogout && <Logout />}
      {notify && <Toast />}
      {showNewsCard && <NewsCard />}
      {showSingleCard && <SingleCard />}
      {authLoading && !authState && <GlobalLoader />}
      {showCreate && <Create />}
      {showCreatePost === "create" && <CreatePost />}
      {showCreatePost === "creating" && (
        <div className="fixed top-0 w-full z-[99999999]">
          <BarLoader
            color="black"
            cssOverride={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "4px",
              backgroundColor: "white",
            }}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ActivityCheck);
