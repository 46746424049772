import { BarLoader } from "react-spinners";
import logoBlack from "../../Assets/loadinglogo.png";
import useStore from "../../Store/useStore";

const quotes = [
  "Zara was the most mentioned brand on Instagram in 2022",
  "Businesses Make $5.20 for Every $1 Spent on Influencer Marketing",
  "Influencers Increased the Purchase Intent by 5.2x on Twitter",
  "Globally, 42.7% of internet users use ad blockers.",
  "49% of Consumers Depend on Influencer Recommendations",
  "Micro-Influencers Are on the Rise, Growing From 68% to 91% in 2022",
  "70% of Teens Trust Influencers More than Traditional Celebrities",
  "86% of Women Use Social Media for Purchasing Advice",
  "Nano-Influencers Have the Highest Engagement Rate at 7%",
  "Influencer Marketing Achieved 11x ROI Than Banner Ads",
  "8 Out of 10 Consumers Purchased Something After Seeing it Recommended by an Influencer",
  "In India, 50.7% of internet users use ad blockers. This is Where Influencer Marketing Come In To Play.",
  "Micro-influencers generate up to 60% more engagement than macro influencers.",
];
const quote = quotes[Math.floor(Math.random() * quotes.length)];

const GlobalLoader = ({ strict }) => {
  return (
    <div
      className={`fixed inset-0 z-[99999999] ${
        strict ? "bg-white" : "bg-[#96959549]"
      } flex items-center justify-center px-5`}
    >
      <BarLoader
        color="black"
        cssOverride={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "4px",
          backgroundColor: "white",
        }}
      />
      {strict && (
        <div className="flex flex-col items-center justify-center -translate-y-full">
          <img loading="lazy" className="w-full max-w-[250px]" src={logoBlack} alt="" />
          <p className="mt-4 text-sm text-center line-clamp-2 md:line-clamp-1 text-black/70">
            “{quote}”
          </p>
        </div>
      )}
    </div>
  );
};
export default GlobalLoader;
