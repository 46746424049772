import React, { useRef } from "react";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiFillLinkedin,
  AiFillApple,
} from "react-icons/ai";
import { HiMail } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo.png";
import playstoreWhite from "../../Assets/playstoreWhite.png";

const Footer = () => {
  const navigate = useNavigate();
  const icons = useRef([
    {
      url: "https://www.instagram.com/flytant/",
      icon: <AiFillInstagram />,
      name: "instagram",
    },
    {
      url: "https://www.facebook.com/flytantapp/",
      icon: <AiFillFacebook />,
      name: "facebook",
    },
    {
      url: "https://twitter.com/flytant",
      icon: <AiFillTwitterSquare />,
      name: "twitter",
    },
    { url: "mailto:contact@flytant.com", icon: <HiMail /> },
    {
      url: "https://www.youtube.com/channel/UC_r46_UgBvaG2k94LDjEIWQ",
      icon: <AiFillYoutube />,
      name: "youtube",
    },
    {
      url: "https://in.linkedin.com/company/flytant",
      icon: <AiFillLinkedin />,
      name: "linkedin",
    },
  ]);
  const links = useRef([
    "Home",
    "Terms",
    "Ads",
    "About",
    "Privacy",
    "FAQs",
    "Career",
    "Contact",
    "Blogs",
  ]);

  return (
    <footer className="bg-black text-white pb-14">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-20 gap-x-5 lg:gap-x-10 xl:gap-x-20 border-y border-[#a8a8a877] r-box py-24 items-center">
        {/* Logo Part */}
        <div className="flex flex-col items-center justify-between">
          <span>
            <img
              loading="lazy"
              onClick={() => {
                navigate("/");
              }}
              className="w-64 cursor-pointer"
              src={logo}
              alt=""
            />
            <p className="my-5 text-center">Connecting Brands & Influencers</p>
          </span>
          {/* Icons Part */}
          <div className="grid grid-cols-3 xl:grid-cols-6 gap-x-2 gap-y-5">
            {icons.current.map((item, i) => (
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={i}
                className="w-[60px] h-[60px] xl:w-12 xl:h-12 border-[3px] xl:border-[2px] rounded-full flex items-center justify-center text-3xl xl:text-2xl hover:scale-110 duration-150"
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
        {/* Links Part */}
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-3 gap-x-14 gap-y-5 w-fit pl-8">
            {links.current.map((item, i) => (
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                key={item}
                className="text-lg xl:text-xl cursor-pointer text-gray-400 hover:text-white duration-150"
                id={`click${i + 1}`}
              >
                {item}
              </Link>
            ))}
          </div>
        </div>
        {/* Apps Part */}
        <div className="flex w-full justify-center items-center">
          <div className="flex flex-col items-center xl:items-start">
            <p className="mt-1 lg:mt-0">Get the apps!</p>
            <div className="flex items-end gap-3 mt-2 mb-4">
              <a
                href="https://play.google.com/store/apps/details?id=influencer.marketing.flytant"
                target="_blank"
                rel="noreferrer"
              >
                <img loading="lazy" className="w-[52px]" src={playstoreWhite} alt="" />
              </a>
              <a
                href="https://apps.apple.com/in/app/flytant/id1530158515"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillApple className="text-6xl" />
              </a>
            </div>
            <div>
              <p>
                <a href="http://www.playwire.com" rel="noreferrer" target="_blank">
                  <img
                    loading="lazy"
                    src="https://www.playwire.com/hubfs/Powered-by-Playwire-Badges/Ads-Powered-by-playwire-2021-standalone-small-white-300px.png"
                    alt="Playwire"
                    className="w-[150px] mx-auto block"
                  />
                </a>
              </p>
              <p className="text-center mt-1 text-xs">
                <a
                  href="/contact"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-400 underline"
                >
                  Advertise on this site.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center text-[#ABABAB] mt-7">© Flytant {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;
