import { useEffect } from "react";
import useStore from "../Store/useStore";
import useRequest from "./useRequest";

const useFollowing = () => {
  const { user, setFollowing } = useStore();
  const { request } = useRequest();

  useEffect(() => {
    if (!user?.userId) return;
    request({ url: "user/getfollowing", params: { userId: user.userId } })
      .then(({ data }) => setFollowing(data))
      .catch(() => {});
  }, [user?.userId]);
};

export default useFollowing;
