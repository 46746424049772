import React, { useCallback, useEffect, useState } from "react";
import { BsAspectRatio } from "react-icons/bs";
import { MdArrowForwardIos } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { GrClose } from "react-icons/gr";

const ModifyFiles = ({ setFiles, setAspectRatio, tempFiles, setTempFiles }) => {
  const [_, setState] = useState(true);
  const [swiper, setSwiper] = useState({});
  const [showAspect, setShowAspect] = useState(false);
  const [tempAspect, setTempAspect] = useState("1/1");

  const updateSwiper = useCallback((newInstance) => {
    setSwiper(newInstance);
    setState((prev) => !prev);
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".tempvideo");
    if (!elements) return;
    const callback = (entries) => {
      entries.forEach(({ isIntersecting, target }) => {
        isIntersecting ? target.play() : target.pause();
      });
    };
    const options = { root: null, rootMargin: "0px", threshold: 1 };
    const observer = new IntersectionObserver(callback, options);
    elements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [tempFiles]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        showAspect && setShowAspect(false);
      }}
      className="bg-black rounded-xl w-full max-w-[600px] overflow-hidden"
    >
      <div className="p-3 pr-4 border-b border-neutral-700 text-white flex items-center justify-between">
        <button onClick={() => setTempFiles([])} className="text-xl">
          <GrClose className="bg-white rounded-full p-1 bg-white/60 active:bg-white lg:hover:bg-white" />
        </button>
        <button
          onClick={() => {
            setFiles(tempFiles);
            setAspectRatio(tempAspect);
            setTempFiles([]);
          }}
        >
          Next
        </button>
      </div>
      <Swiper
        onInit={updateSwiper}
        onSlideChange={updateSwiper}
        onSlidesGridLengthChange={updateSwiper}
        className="w-full aspect-square"
      >
        {tempFiles.map(({ file, preview }) => (
          <SwiperSlide key={file.name} className="w-full h-full flex items-center justify-center">
            {file.type.startsWith("image") && (
              <img
                loading="lazy"
                style={{
                  width: tempAspect === "4/5" ? "80%" : tempAspect === "9/16" ? "56.25%" : "100%",
                  aspectRatio: tempAspect,
                }}
                className="object-cover object-center duration-200"
                src={preview}
                alt=""
              />
            )}
            {file.type.startsWith("video") && (
              <video
                style={{
                  width: tempAspect === "4/5" ? "80%" : tempAspect === "9/16" ? "56.25%" : "100%",
                  aspectRatio: tempAspect,
                }}
                onClick={(e) => (e?.target?.paused ? e?.target?.play() : e?.target?.pause())}
                className="object-cover object-center tempvideo duration-200"
                src={preview}
              ></video>
            )}
          </SwiperSlide>
        ))}
        {!swiper.isBeginning && tempFiles.length > 1 && (
          <div
            onClick={() => swiper?.slidePrev()}
            className="absolute top-1/2 -translate-y-1/2 left-4 z-20 rounded-full flex items-center justify-center cursor-pointer p-2 [&>*]:lg:hover:bg-white [&>*]:active:bg-white"
          >
            <MdArrowForwardIos className=" bg-white/60 text-black  w-6 h-6 rounded-full p-1 rotate-180" />
          </div>
        )}
        {!swiper.isEnd && tempFiles.length > 1 && (
          <div
            onClick={() => swiper?.slideNext()}
            className="absolute top-1/2 -translate-y-1/2 right-4 z-20 rounded-full flex items-center justify-center cursor-pointer p-2 [&>*]:lg:hover:bg-white [&>*]:active:bg-white"
          >
            <MdArrowForwardIos className=" bg-white/60 text-black  w-6 h-6 rounded-full p-1 " />
          </div>
        )}
        {showAspect && (
          <div className="absolute left-4 bottom-16 z-10 bg-neutral-700/90 flex flex-col rounded overflow-hidden text-white/80">
            <button
              onClick={() => setTempAspect("1/1")}
              className="text-sm h-11 text-left px-3 hover:text-white flex items-center gap-3"
            >
              <span>1 : 1</span>
              <span className="w-4 h-4 border rounded-sm" />
            </button>
            <button
              onClick={() => setTempAspect("4/5")}
              className="text-sm h-11 text-left px-3 hover:text-white flex items-center gap-3"
            >
              <span>4 : 5</span>
              <span className="w-4 h-5 border rounded-sm" />
            </button>
            <button
              onClick={() => setTempAspect("16/9")}
              className="text-sm h-11 text-left px-3 hover:text-white flex items-center gap-3"
            >
              <span>16 : 9</span>
              <span className="w-6 h-4 border rounded-sm" />
            </button>
            <button
              onClick={() => setTempAspect("9/16")}
              className="text-sm h-11 text-left px-3 hover:text-white flex items-center gap-3"
            >
              <span>9 : 16</span>
              <span className="w-4 h-6 border rounded-sm" />
            </button>
          </div>
        )}
        <button
          onClick={() => !showAspect && setShowAspect(true)}
          className="bg-white/60 text-black lg:hover:bg-white active:bg-white w-8 h-8 rounded-full flex items-center justify-center absolute left-4 bottom-6 z-10"
        >
          <BsAspectRatio />
        </button>
      </Swiper>
    </div>
  );
};

export default ModifyFiles;
