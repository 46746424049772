import React from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import BrandsDrawer from "../Drawer/BrandsDrawer";
import { useLocation } from "react-router-dom";
import useStore from "../../Store/useStore";

const BrandsWrapper = ({ children, hideFooter }) => {
  const { searchFilters } = useStore();
  const { pathname } = useLocation();
  return (
    <div className="fixed top-0 left-0 inset-0 overflow-y-auto">
      <NavBar />
      <div
        className={`flex items-start r-box ${!hideFooter ? "mb-20" : ""} ${
          pathname === "/search" && searchFilters?.text
            ? "border-t mt-[78px] sm:mt-[82px] lg:mt-[24px] h-[calc(100%-134px)] sm:h-[calc(100%-138px)] md:h-[calc(100%-176px)] lg:h-[calc(100%-120px)] relative"
            : "border-0 h-[calc(100%-56px)] md:h-[calc(100%-96px)]"
        }`}
      >
        <BrandsDrawer />
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default BrandsWrapper;
