import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Blogs from "../../Assets/userHome/drawerItems/Blogs.png";
import BlogsB from "../../Assets/userHome/drawerItems/BlogsB.png";
import Home from "../../Assets/userHome/drawerItems/Home.png";
import HomeB from "../../Assets/userHome/drawerItems/HomeB.png";
import Card from "../../Assets/userHome/drawerItems/Card.png";
import CardB from "../../Assets/userHome/drawerItems/CardB.png";
import Chat from "../../Assets/userHome/drawerItems/Chat.png";
import ChatB from "../../Assets/userHome/drawerItems/ChatB.png";
import Notification from "../../Assets/userHome/drawerItems/Notification.png";
import NotificationB from "../../Assets/userHome/drawerItems/NotificationB.png";
import More from "../../Assets/userHome/drawerItems/More.png";
import MoreB from "../../Assets/userHome/drawerItems/MoreB.png";
import Sponsorships from "../../Assets/brands/drawerItems/Campaign.png";
import SponsorshipsB from "../../Assets/brands/drawerItems/CampaignB.png";
import Subscription from "../../Assets/userHome/drawerItems/Subscription.png";
import Subscriptions from "../../Assets/userHome/drawerItems/SubscriptionB.png";
import Back from "../../Assets/userHome/drawerItems/back.png";
import Courses from "../../Assets/userHome/drawerItems/Courses.png";
import CoursesB from "../../Assets/userHome/drawerItems/CoursesB.png";
import MyCampaigns from "../../Assets/userHome/drawerItems/MyCampaigns.png";
import News from "../../Assets/userHome/drawerItems/News.png";
import NewsB from "../../Assets/userHome/drawerItems/NewsB.png";
import useStore from "../../Store/useStore";
import { BiPlus } from "react-icons/bi";

const UserDrawer = () => {
  const { routes, setRoutes, allMessages, setShowCreate, notifications } = useStore();
  const { pathname } = useLocation();
  const [selected, setSelected] = useState(pathname);
  const navigate = useNavigate();

  const paths = useMemo(() => {
    if (!routes) {
      return [
        { title: "Feed", path: "/", img1: Home, img2: HomeB },
        { title: "Sponsorships", path: "/sponsorships", img1: Sponsorships, img2: SponsorshipsB },
        { title: "Chats", path: "/chats", img1: Chat, img2: ChatB },
        { title: "Notifications", path: "/notifications", img1: Notification, img2: NotificationB },
        { title: "Subscription", path: "/subscription", img1: Subscription, img2: Subscriptions },
        { title: "More", path: "more", img1: More, img2: MoreB },
      ];
    }
    return [
      { title: "My Campaigns", path: "/mycampaigns", img1: MyCampaigns, img2: MyCampaigns },
      { title: "Blogs", path: "/blogs", img1: Blogs, img2: BlogsB },
      { title: "Social Card", path: "/socialcard", img1: Card, img2: CardB },
      { title: "Courses", path: "/courses", img1: Courses, img2: CoursesB },
      { title: "News", path: "/news", img1: News, img2: NewsB },
    ];
  }, [routes]);

  useEffect(() => {
    setSelected(`/${pathname.split("/")[1]}`);
  }, [pathname]);

  return (
    <div className="h-full w-[50px] lg:w-[240px] overflow-y-auto scrollbar border-r pt-5 shrink-0">
      {routes && (
        <div
          onClick={() => {
            setRoutes(false);
          }}
          className="flex items-center gap-8 pl-2 mb-10 cursor-pointer h-8 font-semibold"
        >
          <img loading="lazy" className="w-7 md:w-8" src={Back} alt="" />
          <p className="hidden lg:block ">Back</p>
        </div>
      )}
      <div className="flex flex-col items-start gap-8">
        {paths.map((path) => (
          <div
            onClick={() => {
              path?.path === "more" ? setRoutes(true) : navigate(path?.path);
            }}
            key={path?.title}
            className="flex items-center gap-8 relative cursor-pointer h-8  pl-2"
          >
            {selected === path.path && (
              <span className="absolute w-1 bg-black h-full top-0 left-0" />
            )}
            {path?.title?.toLowerCase() === "chats" && allMessages?.unreadCount > 0 && (
              <p className="absolute -top-2 left-7 w-5 h-5 bg-black border border-white text-white rounded-full flex items-center justify-center text-[11px]">
                {allMessages?.unreadCount}
              </p>
            )}
            {path?.title?.toLowerCase() === "notifications" && notifications?.dot && (
              <span className="absolute -top-1 left-7 w-2.5 h-2.5 bg-red-500 rounded-full" />
            )}
            <img
              loading="lazy"
              src={selected === path.path ? path?.img2 : path.img1}
              alt=""
              className={`w-7 md:w-8 aspect-square ${
                path?.title === "Social Profile" ? "rounded-full" : "rounded-0"
              }`}
            />

            <span
              className={`hidden lg:block text-lg ${
                selected === path.path ? "font-bold" : "font-medium"
              }`}
            >
              {path.title}
            </span>
          </div>
        ))}
        {!routes && (
          <button
            onClick={() => setShowCreate(true)}
            className="bg-black text-md text-white py-2.5 rounded-full font-medium hidden w-[90%] lg:flex items-center justify-center gap-2.5"
          >
            <BiPlus className="w-7 h-7" />
            Create
          </button>
        )}
      </div>
    </div>
  );
};

export default UserDrawer;
