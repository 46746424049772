import React from "react";
import NavBar from "../NavBar/NavBar";
import NftDrawer from "../Drawer/NftDrawer";
import useStore from "../../Store/useStore";
import NftsLogin from "../Nfts/NftsLogin";

const NftsWrapper = ({ children }) => {
  const { nftsAdmin } = useStore();

  if (!nftsAdmin) {
    return <NftsLogin />;
  }

  return (
    <div className="fixed top-0 left-0 inset-0 overflow-y-auto">
      <NavBar />
      <div className="h-[calc(100%-56px)] md:h-[calc(100%-96px)] flex items-start r-box mb-20">
        <NftDrawer />
        {children}
      </div>
    </div>
  );
};

export default NftsWrapper;
